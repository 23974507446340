import { Card, Col, Collapse, Row, Space, Typography } from 'antd'
import React from 'react'
import { FilePdfOutlined, FileOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink, useParams } from 'react-router-dom';
import LearnVideoList from './LearnVideoList';
import { calculateUnitTotalDuration, calculateUnitTotalVideos, formatDuration } from '../../utils';
import LearnDocumentList from './LearnDocumentList';

interface LearnUnitDetailProps {
    unit: any;
    index: number;
}

const LearnUnitDetail: React.FC<LearnUnitDetailProps> = ({ unit, index }) => {
    const { Title, Text } = Typography;
    const { id, subjectId, courseId, unitId }: any = useParams();

    const unitTtitleRender = (unit: any) => {
        let unitTotalDuration = calculateUnitTotalDuration(unit);
        let totalVideo = calculateUnitTotalVideos(unit);
        return <Space direction="vertical">
            <Text style={{ fontWeight: 600 }} className='m-0'>Ünite {index + 1}: {unit?.unitName}</Text>
            <span>
                {/* <Text type="secondary">/{totalVideo} - </Text> */}
                {/* <Text type="secondary">{formatDuration(unitTotalDuration)}</Text> */}
            </span>
        </Space>
    }

    return (
        <>
            <Collapse
                bordered={false}
                accordion
                defaultActiveKey={[unitId]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
                style={{ padding: '0px', borderRadius: 0 }}
                items={[{
                    key: unit.unitId, label: <>{unitTtitleRender(unit)}</>, children:
                        <>
                            {unit?.subjects?.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((subject: any, index: number) => (
                                <>
                                    <p style={{ fontWeight: 500, padding: 0, paddingLeft: 16, margin: 0, marginBottom: 16, marginTop: 16}}>{subject?.title}</p>
                                    <LearnVideoList index={index} subject={subject}></LearnVideoList>
                                    {(subject.documents.length > 0) && <LearnDocumentList index={index} subject={subject}></LearnDocumentList>}
                                </>
                            ))}
                        </>
                }]}
            />
        </>

    )
}

export default LearnUnitDetail

{/* {subject?.explanations?.map((explanation: any) => (
                                                <Col>
                                                    <NavLink to={`/explanation/${explanation?.id}`}>
                                                        <Card className='border-radius-12 min-h-150 w-150 bg-orange p-16' bodyStyle={{ padding: '0px' }}>
                                                            <Row>
                                                                <Col>
                                                                    <p className='font-color-white fw-500 fs-14 m-0 p-0 mh-75'>{explanation?.title}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={"end"}>
                                                                <Col>
                                                                    <FileOutlined className='mt-24' style={{ fontSize: '24px', color: "white" }} />
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </NavLink>
                                                </Col>
                                            ))} */}
{/* {(subject?.questions?.length > 0) && <Col>
                                                                <NavLink to={`/questions/${subject?.id}`}>
                                                                    <Card className='border-radius-12 min-h-150 w-150 bg-green p-16' bodyStyle={{ padding: '0px' }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className='font-color-white fw-500 fs-14 m-0 p-0 mh-75'>Sorular</p>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row justify={"end"}>
                                                                            <Col>
                                                                                <QuestionCircleOutlined className='mt-24' style={{ fontSize: '24px', color: "white" }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </NavLink>
                                                            </Col>} */}
{/* {subject?.documents?.map((document: any) => (
                                                <Col>
                                                    <NavLink to={`/document/${document?.id}`}>
                                                        <Card className='border-radius-12 min-h-150 w-150 bg-gray p-16' bodyStyle={{ padding: '0px' }}>
                                                            <Row>
                                                                <Col>
                                                                    <p className='font-color-white fw-500 fs-14 m-0 p-0 mh-75'>{document?.title}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={"end"}>
                                                                <Col>
                                                                    <FilePdfOutlined className='mt-24' style={{ fontSize: '24px', color: "white" }} />
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </NavLink>
                                                </Col>
                                            ))} */}