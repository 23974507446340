import { http } from "../http-common";
import { IQuestionResponse } from "../types/Question";
import { isAuthorized, openNotification } from "../utils";

const submitQuiz = (obj:any) => {
    return http.post<any>(`/quiz-answers`, obj);
    
    //Permission yok
    if (isAuthorized('quizanswers.list')) {
        return http.get<IQuestionResponse>(`/question`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getQuizResult = (id:any) => {
    return http.get<any>(`/quiz-answers/me/${id}`);
};

const QuizAnswersService = {
    submitQuiz,
    getQuizResult
    // getFile,
}

export default QuizAnswersService;