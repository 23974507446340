import { http } from "../http-common";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('assignment.list')) {
        return http.get<any>(`/assignments`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAllMe = (data: any) => {
    if (isAuthorized('assignment.list')) {
        return http.get<any>(`/assignments/me${data}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAllMeStatistic = () => {
    if (isAuthorized('assignment.list')) {
        return http.get<any>(`/assignments/me/statistics`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('assignment.show')) {
        return http.get<any>(`/assignments/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};


const create = (data: any) => {
    if (isAuthorized('assignment.create')) {
        return http.post<any>(`/assignments`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: any, file: any) => {
    const formData = new FormData();
    formData.append('responseImage', file);
    if (data.responseContent) {
        formData.append('responseContent', data.responseContent);
        formData.append('isDone', data.isDone);
    }

    if (isAuthorized('assignment.update')) {
        return http.patch<any>(`/assignments/${id}`, formData);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    return http.get<any>(`/assignments/download?file=${fileName}`, { responseType: 'arraybuffer' });
};

const AssignmentService = {
    create,
    getAll,
    update,
    get,
    getFile,
    getAllMe,
    getAllMeStatistic
}

export default AssignmentService;