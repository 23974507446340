// ProfileImageSelector.tsx
import React, { useEffect, useState } from 'react';
import { Avatar, Modal, Row, Col, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import UserService from '../../services/UserService';
import { openNotification } from '../../utils';

interface Profile {
  id: number;
  fileName: string;
  title: string;
  backgroundColor: string;
}

const profiles: Profile[] = [
  { id: 1, fileName: 'albert-einstein.png', title: 'Albert Einstein', backgroundColor: '#E4CA00' },
  { id: 2, fileName: 'batlamyus.png', title: 'Batlamyus', backgroundColor: '#FF9E00' },
  { id: 3, fileName: 'herodot.png', title: 'Herodot', backgroundColor: '#A96E00' },
  { id: 4, fileName: 'immanuel-kant.png', title: 'Immanuel Kant', backgroundColor: '#FF94B6' },
  { id: 5, fileName: 'isaac-newton.png', title: 'Isaac Newton', backgroundColor: '#005BA1' },
  { id: 6, fileName: 'marie-curie.png', title: 'Marie Curie', backgroundColor: '#E8E2F6' },
  { id: 7, fileName: 'niels-bohr.png', title: 'Niels Bohr', backgroundColor: '#4191E1' },
  { id: 8, fileName: 'platon.png', title: 'Platon', backgroundColor: '#EE9400' },
  { id: 9, fileName: 'rene-descartes.png', title: 'Rene Descartes', backgroundColor: '#A35947' },
  { id: 10, fileName: 'richard-feynman.png', title: 'Richard Feynman', backgroundColor: '#F67D5F' },
  { id: 11, fileName: 'robert-oppenheimer.png', title: 'Robert Oppenheimer', backgroundColor: '#FD8B49' },
  { id: 12, fileName: 'rosalind-franklin.png', title: 'Rosalind Franklin', backgroundColor: '#F25758' },
  { id: 13, fileName: 'socrates.png', title: 'Socrates', backgroundColor: '#FD5B5A' },
  { id: 14, fileName: 'thales.png', title: 'Thales', backgroundColor: '#D3A43A' },
  { id: 15, fileName: 'william-shakespeare.png', title: 'William Shakespeare', backgroundColor: '#32A9C9' },
  { id: 16, fileName: 'chien-shiung-wu.png', title: 'Chien-Shiung Wu', backgroundColor: '#82869b' },
  { id: 17, fileName: 'ada-lovelace.png', title: 'Ada Lovelace', backgroundColor: '#e9d7c9' },
  { id: 18, fileName: 'vera-rubin.png', title: 'Vera Rubin', backgroundColor: '#5f787d' },
  { id: 19, fileName: 'katherine-johnson.png', title: 'Katherine Johnson', backgroundColor: '#3d94a3' },
  { id: 20, fileName: 'jennifer-doudna.png', title: 'Jennifer Doudna', backgroundColor: '#373e4d' },
  { id: 21, fileName: 'emmanuelle-charpentier.png', title: 'Emmanuelle Charpentier', backgroundColor: '#c73c30' },
  { id: 22, fileName: 'lise-meitner.png', title: 'Lise Meitner', backgroundColor: '#abbf2e' },
];

const ProfileImageSelector: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [firstLetter, setFirstLetter] = useState<string>('');

  useEffect(() => {
    // Load profile picture ID from local storage and find the corresponding profile
    const profileId = localStorage.getItem('profilePicture');
    const profile = profileId ? profiles.find((p) => p.id === parseInt(profileId)) : null;

    if (profile) {
      setSelectedProfile(profile);
    } else {
      const firstName = localStorage.getItem('firstName') || '';
      setFirstLetter(firstName.charAt(0));
      setSelectedProfile(null);
    }
  }, []);

  const showProfileModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    if (selectedProfile) {
      // Update the profile picture in the backend using the service
      UserService.updateMe({ profilePicture: selectedProfile.id })
        .then(() => {
          localStorage.setItem('profilePicture', selectedProfile.id.toString());
          openNotification('success', 'Profil Güncellendi', 'Profil fotoğrafı başarıyla güncellendi.');
          setIsModalVisible(false);
        })
        .catch((error: Error) => {
          console.error(error);
          openNotification('error', 'Güncelleme Başarısız', 'Profil fotoğrafı güncellenemedi.');
        });
    } else {
      setIsModalVisible(false);
    }
  };

  const handleProfileSelect = (profile: Profile) => {
    setSelectedProfile(profile);
  };

  const renderProfileImage = () => {
    if (selectedProfile) {
      return (
        <Avatar
          size={88}
          shape="square"
          src={require(`../../assets/avatar/${selectedProfile.fileName}`)}
          alt={selectedProfile.title}
          style={{ backgroundColor: selectedProfile.backgroundColor }}
        />
      );
    }

    return <Avatar size={88} shape="square" style={{ backgroundColor: '#935ac0' }}>{firstLetter}</Avatar>; // Default background if no profile selected
  };

  return (
    <>
      <div onClick={showProfileModal} style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
        {renderProfileImage()}
        <EditOutlined style={{ position: 'absolute', bottom: 0, right: 0, fontSize: '16px', backgroundColor: '#fff', borderRadius: '50%' }} />
      </div>
      <Modal
        title="Profil Fotoğrafı Seç"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Row gutter={[16, 16]}>
          {profiles.map(profile => (
            <Col key={profile.id} span={6}>
              <Tooltip title={profile.title}>
                <div
                  onClick={() => handleProfileSelect(profile)}
                  style={{
                    boxShadow: profile.id === selectedProfile?.id ? '0 0 10px 2px #1890ff' : 'none', // Shadow for selected profile
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    backgroundColor: profile.backgroundColor,
                    transition: 'box-shadow 0.3s ease-in-out' // Smooth transition for shadow effect
                  }}
                >
                  <Avatar
                    src={require(`../../assets/avatar/${profile.fileName}`)}
                    alt={profile.title}
                    style={{ width: 80, height: 80 }}
                  />
                </div>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default ProfileImageSelector;
