import { Card, Drawer, Divider, Tag, Popconfirm, DatePicker, Descriptions, Row, Tooltip, Col, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatDate, openNotification } from '../../utils';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/tr_TR';
import LiveLessonLink from './LiveLessonLink';
import LiveLessonService from '../../services/LiveLessonService';

const LiveLessonDetail: React.FC<any> = ({ item, getLiveLessons }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lesson, setLesson] = useState<any>({});
    const [liveLessonDate, setLiveLessonDate] = useState(dayjs(item.item.liveLessonDate));

    const showModal = () => {
        setLoading(true);
        setIsModalOpen(true);
        getLesson(item.id);
    };

    const getLesson = (id: number) => {
        LiveLessonService.get(id)
            .then((response: any) => {
                let data = response.data;
                setLesson(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const confirmDeleteLesson = () => {
        LiveLessonService.remove(item.id)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ders Silindi');
                getLiveLessons();
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handleLiveLessonDateChange = (value: any) => {
        if (value) {
            const formattedDate = value.format("YYYY-MM-DDTHH:mm");
            LiveLessonService.update(item.id, { liveLessonDate: formattedDate })
                .then(() => {
                    openNotification('success', 'Başarılı', 'Tarih başarıyla güncellendi.');
                    setLiveLessonDate(value);
                    getLesson(item.id);
                    getLiveLessons();
                })
                .catch((e) => {
                    console.error(e);
                    openNotification('error', 'Error', 'An error occurred while updating the due date.');
                });
        }
    };

    const getColor = (item: any) => {
        switch (item.status) {
            case 'PENDING':
                return 'yellow';
            case 'ACCEPT':
                return 'blue';
            case 'REJECT':
                return 'red';
            case 'COMPLETED':
                return 'green';
            default:
                return 'orange';
        }
    };

    const getStatusInTurkish = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Beklemede';
            case 'ACCEPT':
                return 'Onaylandı';
            case 'REJECT':
                return 'Reddedildi';
            case 'COMPLETED':
                return 'Tamamlandı';
            default:
                return 'Bilinmiyor';
        }
    };

    return (
        <>
            <Tooltip title={item.item.note ?? ''} placement="top">
                <Tag
                    style={{ cursor: 'pointer', padding: 5, marginBottom: 10 }}
                    onClick={showModal}
                    color={getColor(item.item)}
                >
                    {item.item?.lessonName}
                </Tag>
            </Tooltip>
            <Drawer
                width={480}
                title="Canlı Ders Detay" placement="right" onClose={handleCancel} open={isModalOpen}
                extra={
                    item?.item?.status === 'PENDING' && (
                        <Popconfirm
                            title="Canlı dersi silmek istediğinizden emin misiniz?"
                            onConfirm={confirmDeleteLesson}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <DeleteOutlined style={{ marginRight: 8, fontSize: '20px' }} />
                        </Popconfirm>
                    )}>
                <Spin spinning={loading} >
                    <Descriptions bordered>
                        <Descriptions.Item label="Ders" span={3}>{lesson?.lessonName}</Descriptions.Item>
                        <Descriptions.Item label="Durum" span={3}>
                            <Tag color={getColor(item.item)}>{getStatusInTurkish(lesson.status)}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ders Tarihi" span={3}>
                            {lesson?.status === 'PENDING' ? <DatePicker
                                readOnly={lesson?.status === 'PENDING' ? false : true}
                                showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
                                style={{ width: "100%" }}
                                format="DD/MM/YYYY HH:mm"
                                placeholder="Tarih Seç"
                                defaultValue={liveLessonDate}
                                onChange={handleLiveLessonDateChange}
                                locale={locale}
                            /> : formatDate(lesson?.liveLessonDate)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Not" span={3}>{lesson?.note}</Descriptions.Item>
                        <Descriptions.Item label="Bağlantı" span={3}><LiveLessonLink link={lesson?.link}></LiveLessonLink></Descriptions.Item>
                    </Descriptions>
                </Spin>
            </Drawer>
        </>
    );
}
export default LiveLessonDetail;
