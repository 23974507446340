import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QuizService from '../services/QuizService';
import { Badge, Button, Card, Col, Radio, Row, Space, Statistic, Tag, Typography, Image, Pagination, RadioChangeEvent, Popconfirm, Empty } from 'antd';
import type { PaginationProps } from 'antd';
import Countdown from 'antd/es/statistic/Countdown';
import ExamImg from '../assets/Dashboard/exam.jpeg';
import QuestionService from '../services/QuestionService';
import type { CountdownProps } from 'antd';
import QuizAnswersService from '../services/QuizAnswersService';
import QuizResult from '../components/Quizzes/QuizResult';

const { Title } = Typography;

function QuizDetail() {
    const { id } = useParams();
    const [quiz, setQuiz] = useState<any>();
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [resultStep, setResultStep] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [duration, setDuration] = useState<any>(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [quizResult, setQuizResult] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [isPreviouslyStarted, setIsPreviouslyStarted] = useState(false);

    const getQuiz = () => {
        setLoading(true);
        QuizService.get(id)
            .then((response: any) => {
                let data = response.data;
                data.questions = questionsSortByLessonName(data?.questions);
                questionsGroupByLessonName(data?.questions);
                setQuiz(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    useEffect(() => {
        const previouslyStartedQuiz = localStorage.getItem(`quiz_${id}`);
        if (previouslyStartedQuiz) {
            setIsPreviouslyStarted(true);
        }
        getQuiz();
    }, [id]);

    useEffect(() => {
        if (isStart) {
            setDuration(Date.now() + 1000 * 60 * quiz?.duration + 1000);
            setStartTime(Date.now());
            getFile();
            localStorage.setItem(`quiz_${id}`, 'started');
        }
    }, [isStart, quiz?.duration]);

    useEffect(() => {
        getFile();
    }, [currentQuestion]);

    const questionsSortByLessonName = (questions: any[]) => {
        questions = questions.map((q: any) => ({
            ...q,
            question: {
                ...q.question,
                answer: null,
                file: null,
            },
        }));
        questions = questions.sort((a: any, b: any) => a.question.id - b.question.id);
        return questions.sort((a: any, b: any) => a.lesson.name.localeCompare(b.lesson.name));
    };

    const questionsGroupByLessonName = (questions: any[]) => {
        const groupedQuestions = questions.reduce((acc: any, current: any) => {
            const lessonName = current.lesson.name;
            if (!acc[lessonName]) {
                acc[lessonName] = {
                    id: current.lesson.id,
                    name: current.lesson.name,
                    questions: [],
                };
            }
            acc[lessonName].questions.push(current.question);
            return acc;
        }, {});

        let result: any = Object.values(groupedQuestions);

        result = result.sort((a: any, b: any) => a.name.localeCompare(b.name));

        result.forEach((lesson: any) => {
            lesson.questions.sort((a: any, b: any) => a.id - b.id);
        });

        setLessons(result);
    };

    const onChange = (index: any) => (e: RadioChangeEvent) => {
        let selected = e.target.value;
        let quizData = { ...quiz };
        quizData.questions[index].question.answer = selected;
        setQuiz(quizData);
    };

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isStart) {
                const confirmationMessage = 'Are you sure you want to leave? Your progress will be lost.';
                e.preventDefault(); // Modern tarayıcılar için
                e.returnValue = confirmationMessage; // Eski tarayıcılar için
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isStart]);

    const renderQuestionList = (questions: any[]) => {
        if (!questions) {
            return false;
        }
        let lessonName = "";
        return (
            <>
                {questions.map((row: any, index: any) => {
                    const showLessonName = lessonName !== row.lesson.name;
                    lessonName = row.lesson.name;
                    return (
                        <div key={row.question.id}>
                            {showLessonName && (
                                <Row>
                                    <Col span={24}>
                                        <h3>{row.lesson.name}</h3>
                                    </Col>
                                </Row>
                            )}
                            <Row align={'middle'} className='mb-16' key={row.question.id}>
                                <Col span={1}>
                                    <b>{index + 1}</b>
                                </Col>
                                <Col span={23}>
                                    <Card bodyStyle={{ padding: 0 }}>
                                        <div>
                                            {renderOptionsSider(row.question, index)}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderOptionsSider = (question: any, index: any) => {
        const options = ['A', 'B', 'C', 'D', 'E'].slice(0, question?.answerType);
        return (
            <Button type="link" onClick={() => { setCurrentQuestion(index + 1) }} style={{ width: '100%', padding: 0 }}>
                <Radio.Group onChange={onChange(index)} value={question?.answer}>
                    <Space>
                        {options.map(option => (
                            <Radio className='border-radius-lg' key={option} value={option}>
                                {option}
                            </Radio>
                        ))}
                        <Radio className='border-radius-lg' key={null} value={null}>Boş</Radio>
                    </Space>
                </Radio.Group>
            </Button>
        );
    };

    const renderOptions = (question: any) => {
        const options = ['A', 'B', 'C', 'D', 'E'].slice(0, question?.answerType);
        return (
            <Radio.Group onChange={onChange(currentQuestion - 1)} value={question?.answer}>
                <Col>
                    {options.map(option => (
                        <Radio className='border-cloud px-16 py-8 border-radius-lg mb-16' key={option} value={option}>{option}</Radio>
                    ))}
                    <Radio className='border-cloud px-16 py-8 border-radius-lg mb-16' key={null} value={null}>Boş</Radio>
                </Col>
            </Radio.Group>
        );
    };

    const renderLessonList = (lessons: any[]) => {
        return (
            <Row gutter={[16, 16]}>
                {lessons?.map((lesson: any, index: any) => (
                    <Col>
                        <Badge color="#fed449" key={index} count={lesson.questions.length}>
                            <Tag color="default">{lesson.name}</Tag>
                        </Badge>
                    </Col>
                ))}
            </Row>
        );
    };

    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <Button type="primary" size="small">Önceki Soru</Button>;
        }
        if (type === 'next') {
            return <Button type="primary" size="small">Sonraki Soru</Button>;
        }
        return originalElement;
    };

    const getFile = async () => {
        try {
            setFile(null);
            let fileName = quiz?.questions[currentQuestion - 1]?.question?.question;
            if (fileName) {
                const response = await QuestionService.getFile(fileName);
                const pdfBlob = new Blob([response?.data], { type: 'application/jpg' });
                let fileData = URL.createObjectURL(pdfBlob);

                // Eski blob URL'sini serbest bırak
                if (file) {
                    URL.revokeObjectURL(file);
                }

                setFile(fileData);
                let quizData = { ...quiz };
                quizData.questions[currentQuestion - 1].question.file = response?.data;
                setQuiz(quizData);
            }
        } catch (e) {
            console.error('Dosya getirilirken hata oluştu:', e);
        }
    };

    const quizAnswersSubmit = () => {
        setIsSubmit(true);
        const answeredQuestions = quiz?.questions.map((row: any) => (
            {
                questionId: row.question.id,
                option: row.question.answer,
            }
        ));

        const endTime = Date.now();
        const duration = startTime !== null ? Math.floor((endTime - startTime) / 1000) : 0;

        const postObj = {
            quizId: id,
            answeredQuestions,
            resultDuration: duration,
        };

        QuizAnswersService.submitQuiz(postObj)
            .then((response: any) => {
                let data = response.data;
                setIsSubmit(false);
                setQuizResult(data);
                setResultStep(true);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const onChangeCountDown: CountdownProps['onChange'] = (val: any) => {
        // console.log("onChangeCountDown", (parseInt(val) / 1000 / 60));
    };

    const handleQuestionClick = (index: number) => { };

    return (
        <>
            <Card loading={loading}>
                <Row gutter={[32, 32]} justify={"space-between"}>
                    <Col>
                        <Title className="m-0 p-0" level={4}>#{quiz?.id} - {quiz?.title}</Title>
                        {(isStart && !resultStep) && <Popconfirm
                            title="Sınavı bitirmek istediğinizden emin misiniz?"
                            onConfirm={quizAnswersSubmit}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button disabled={isSubmit} type="primary" className="mr-16">
                                Sınavı Tamamla
                            </Button>
                        </Popconfirm>}
                    </Col>
                    <Col>
                        <Title className="m-0 p-0" level={5}>Dersler</Title>
                        {renderLessonList(lessons)}
                    </Col>
                    <Col>
                        <Statistic title="Toplam Soru" value={`${quiz?.questions.length}`} />
                    </Col>
                    <Col>
                        {(isStart && duration && !resultStep) ? <Countdown title="Sınav Süresi" value={duration} onChange={onChangeCountDown} format="HH:mm:ss" /> : <Statistic title="Sınav Süresi" value={`${quiz?.duration} dakika`} />}
                    </Col>
                </Row>
                {(!isStart) && <Row justify={"center"}>
                    <Image preview={false} style={{ maxHeight: "400px", width: "100%" }} className='mt-32' src={ExamImg}></Image>
                    <Col span={24}>
                        <Row justify={"center"}>
                            <Title level={2}>{isPreviouslyStarted ? 'Sınavı daha önce yaptınız' : 'Hazır olduğunda başlayabilirsiniz'}</Title>
                        </Row>
                    </Col>
                    <Button onClick={() => { setIsStart(true) }} type="primary" size="large" disabled={isPreviouslyStarted}>Başlat</Button>
                </Row>}
            </Card>
            {(isStart && !resultStep) && <Row className='mt-24' gutter={[24, 24]}>
                <Col xxl={16} xl={14} lg={12} xs={24}>
                    <Card title={<Row justify={"start"} gutter={[16, 16]}>
                            <Col>
                                Soru: {currentQuestion}
                            </Col>
                            <Col>
                                Konu: {quiz?.questions[currentQuestion - 1].question.subject}
                            </Col>
                        </Row>} 
                        extra={<Pagination
                            simple
                            size="default"
                            current={currentQuestion}
                            total={quiz?.questions.length}
                            pageSize={1}
                            onChange={(page: number) => { setCurrentQuestion(page) }}
                            itemRender={itemRender}
                        />} 
                        loading={loading}>
                        {(file !== null) ? <Row justify={"center"}>
                            <Image style={{ maxHeight: '500px', width: "100%" }} src={file} fallback="error.png" />
                        </Row> : <Empty description={
                            <Typography.Text>
                                Yükleniyor
                            </Typography.Text>
                        } />}
                        <Row justify={"center"} className='mt-24'>
                            {renderOptions(quiz?.questions[currentQuestion - 1].question)}
                        </Row>
                    </Card>
                </Col>
                <Col xxl={8} xl={10} lg={12} xs={24}>
                    <Card loading={loading} bodyStyle={{ padding: '10px' }}>
                        <Title className='p-0 m-0' level={3}>Sorular</Title>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                {renderQuestionList(quiz?.questions)}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>}
            {(resultStep) && <QuizResult quizResult={quizResult} questions={quiz?.questions} onQuestionClick={handleQuestionClick} />
            }
        </>
    );
}

export default QuizDetail;
