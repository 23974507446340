import { useEffect, useState } from 'react';
import { Table, Select, Modal, Image, Form, Button, Row, Upload, UploadProps, GetProp, UploadFile, Col, Card, Tag } from 'antd';
import StudentQuestionService from '../services/StudentQuestionService';
import LessonService from '../services/LessonService';
import { formatDate, openNotification } from '../utils';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import DetailButton from '../components/StudentQuestions/DetailButton';
import { NavLink } from 'react-router-dom';

const { Option } = Select;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const StudentQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);
  const [filterLessonId, setFilterLessonId] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [formLessonId, setFormLessonId] = useState<string | undefined>(undefined); // Yeni state değeri eklendi

  let authId = localStorage.getItem('id');
  const [form] = Form.useForm();
  const id = localStorage.getItem('id')
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const getQuestionsAndLessons = async () => {
    setLoading(true);
    try {
      const [lessonResponse, questionResponse] = await Promise.all([
        LessonService.getAll(),
        StudentQuestionService.getAll(id),
      ]);

      const lessonsData: any = lessonResponse?.data;
      const questionsData = questionResponse?.data.filter((question: any) => question.studentId.toString() === authId);

      // Map lessons to a useful structure
      const lessonsMap = new Map(lessonsData.map((lesson: any) => [lesson.id, lesson.name]));

      // Enrich questions with lesson names
      const enrichedQuestions = questionsData.map((question: any) => ({
        ...question,
        lessonName: lessonsMap.get(question.lessonId) || 'Ders Bulunamadı',
        answer: question.answer
      }));

      setLessons(lessonsData);
      setQuestions(enrichedQuestions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuestionsAndLessons();
  }, []);

  const handleLessonFilterChange = (value: string) => {
    setFilterLessonId(value);
  };


  const filteredQuestions = questions.filter(question =>
    filterLessonId ? question.lessonId.toString() === filterLessonId : true
  );

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );

  const columns = [
    {
      title: 'Ders',
      dataIndex: 'lessonName',
      key: 'lessonName',
    },
    {
      title: 'Soru',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Oluşturulma Tarihi',
      key: 'action',
      render: (_: any, record: any) => (
        <>
          {formatDate(record.createdAt)}
        </>
      )
    },
    {
      title: 'Cevap',
      dataIndex: 'answer',
      key: 'answer',
      render: (text: string | null) => {
        return (
          <Tag color={text === null ? 'error' : 'success'}>
            {text === null ? 'Öğretmenden Yanıt Bekleniyor' : text}
          </Tag>
        );
      }
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: any) => (
        <DetailButton record={record} />
      )
    },

  ];

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.jpg', // Accept only jpg files
    multiple: false, // Disable multiple file selection
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFormLessonChange = (value: string) => {
    setFormLessonId(value);
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then(values => {
        form.resetFields();
        let postObj: any = {
          studentId: parseInt(authId || '0', 10),
          lessonId: parseInt(formLessonId || '0'), // Kullanıcı tarafından seçilen ders ID'si
          question: values.question,
        };

        StudentQuestionService.create(postObj, fileList[0])
          .then(() => {
            openNotification('success', 'Başarılı', 'Soru Oluşturuldu.');
            getQuestionsAndLessons();
            setIsModalVisible(false);
          }).catch((e: Error) => {
            console.log(e)
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Card bodyStyle={{ paddingTop: '0px' }} title="Sorular"
        extra={<Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
          <Col>
            <Button size="large" onClick={showModal} type="primary">
              Soru Sor
            </Button>
          </Col>
          <Col>
            <Select style={{ width: 200 }} size="large" placeholder="Derse Göre Filtrele" onChange={handleLessonFilterChange} allowClear onClear={() => setFilterLessonId(undefined)}>
              {lessons.map((lesson) => (
                <Option key={lesson.id} value={lesson.id.toString()}>{lesson.name}</Option>
              ))}
            </Select>
          </Col>
          <Col>
            <NavLink to="/" >
              <Button size="large">
                Geri
              </Button>
            </NavLink>
          </Col>
        </Row>}>
        <div style={{ overflowX: 'auto' }}>
          <Table loading={loading} scroll={{ x: 'max-content' }} dataSource={filteredQuestions} columns={columns} rowKey="id" />
        </div>
      </Card>
      <Modal title="Yeni Soru Oluştur" visible={isModalVisible} onOk={handleCreate} onCancel={handleCancel} okText="Oluştur" cancelText="İptal">
        <Form form={form} layout="vertical">
          <Form.Item name="studentQuestion" label="Soru Yükle" >
            <Upload {...props} listType="picture-card"
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                className='max-height-300'
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item name="lessonId" label="Ders" rules={[{ required: true, message: 'Ders seçiniz' }]}>
            <Select
              placeholder="Ders"
              style={{ width: '100%' }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const normalizeText = (text: string) =>
                  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                return normalizeText(option?.children as unknown as string).includes(normalizeText(input));
              }}
              filterSort={(optionA, optionB) => {
                const normalizeText = (text: string) =>
                  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                return normalizeText(optionA?.children as unknown as string).localeCompare(normalizeText(optionB?.children as unknown as string));
              }}
            >
              {lessons.map((lesson: any) => (
                <Option key={lesson.id} value={lesson.id}>
                  {lesson.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="question" label="Soru" rules={[{ required: true, message: 'Soru giriniz' }]}>
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default StudentQuestions;
