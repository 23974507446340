import { Card, Col, Collapse, Row, Space, Typography } from 'antd'
import React from 'react'
import { FilePdfOutlined, FileOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink, useParams } from 'react-router-dom';
import { formatDuration } from '../../utils';

interface LearnVideoListProps {
    subject: any;
    index: number;
}

const LearnDocumentList: React.FC<LearnVideoListProps> = ({ subject, index }) => {
    const { id, subjectId, courseId }: any = useParams();
    const { Title, Text } = Typography;

    return (
        <>
            <Space direction="vertical" style={{ background: 'white', width: '100%' }}>
                {subject?.documents?.map((document: any) => (
                    <NavLink className={'p-16'} replace={true} target='_blank' to={courseId ? `/document/${document?.id}` : ""}>
                        <Space direction="vertical">
                            <Text style={{ fontWeight: 500 }}><FilePdfOutlined style={{ color: "#935ac0", marginLeft: 24, marginRight: 8 }} />{document?.title}</Text>
                        </Space>
                    </NavLink>
                ))}
            </Space>
        </>
    )
}

export default LearnDocumentList