interface Video {
    id: number;
    title: string;
    link: string | null;
    description: string | null;
    cover: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    subjectId: number;
    vimeoId: number;
    duration: number;
    isCompleted?: boolean;
    watchedDuration?: number;
}

interface Subject {
    id: number;
    title: string;
    description: string | null;
    unitId: number;
    unitName: string;
    lessonId: number;
    sortOrder: number;
    unitSortOrder: number;
    lessonName: string;
    gradeId: number;
    gradeName: string;
    videos: Video[];
    documents: any[];
    explanations: any[];
    quizzes: any[];
    questions: any[];
    totalDuration: number | null;
    watchedDuration: number | null;
}

interface Unit {
    unitId: number;
    unitName: string;
    unitSortOrder: number;
    lessonId: number;
    gradeId: number;
    subjects: Subject[];
}

interface Lesson {
    lessonName: string;
    lessonId: number;
    units: Unit[];
}

export const transformDataNew = (subjects: Subject[], videoList: any[] = []): Lesson[] => {

    const groupedData: Lesson[] = [];

    subjects.forEach((subject) => {
        // Find or create the lesson at the top level
        let lesson = groupedData.find((l) => l.lessonId === subject.lessonId);
        if (!lesson) {
            lesson = { lessonId: subject.lessonId, lessonName: subject.lessonName, units: [] };
            groupedData.push(lesson);
        }

        // Find or create the unit within the lesson
        let unit = lesson.units.find((u) => u.unitId === subject.unitId);
        if (!unit) {
            unit = { 
                unitId: subject.unitId, 
                unitName: subject.unitName, 
                unitSortOrder: subject.unitSortOrder, 
                lessonId: subject.lessonId, 
                gradeId: subject.gradeId, 
                subjects: [] 
            };
            lesson.units.push(unit);
        }

        subject.videos = subject.videos.map(video => {
            const matchedVideo = videoList.find(v => v.videoId === video.id);
            return {
                ...video,
                isCompleted: matchedVideo?.isCompleted ?? false,
                watchedDuration: matchedVideo?.watchedDuration ?? 0,
            };
        });

        // Add the subject to the unit
        unit.subjects.push(subject);
    });

    // Sort lessons primarily by lessonId (although they are already grouped this way inherently)
    groupedData.sort((a, b) => a.lessonId - b.lessonId);

    // Sort units within each lesson by unitSortOrder
    groupedData.forEach((lesson) => {
        lesson.units.sort((a, b) => {
            // First compare by lessonId (if necessary) and then by unitSortOrder
            if (a.gradeId === b.gradeId) {
                return a.unitSortOrder - b.unitSortOrder;
            }
            return a.gradeId - b.gradeId;
        });

        // Sort subjects within each unit by sortOrder
        lesson.units.forEach((unit) => {
            unit.subjects.sort((a, b) => a.sortOrder - b.sortOrder);
        });
    });

    return groupedData;
};
