import { http } from "../http-common";
import { IDocumentRequest, IDocumentResponse } from "../types/Documents";
import { openNotification } from "../utils";
let permissions = localStorage.getItem('permissions')

function isAuthorized(requiredPermission: any) {
    return permissions?.includes(requiredPermission);
}

const getAll = () => {
    return http.get<IDocumentResponse>(`/document`);

    if (isAuthorized('document.list')) {
        return http.get<IDocumentResponse>(`/document`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IDocumentRequest, file: any) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("subjectId", data.subjectId.toString());

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post<IDocumentRequest>(`/document`, formData, config);

    if (isAuthorized('document.create')) {
        return http.post<IDocumentRequest>(`/document`, formData, config);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    return http.get<any>(`/document/download?file=${fileName}`, { responseType: 'blob' });
};

const get = (id: any) => {
    return http.get<IDocumentRequest>(`/document/${id}`);

    if (isAuthorized('document.show')) {
        return http.get<IDocumentRequest>(`/document/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IDocumentRequest) => {
    return http.patch<IDocumentRequest>(`/document/${id}`, data);

    if (isAuthorized('document.update')) {
        return http.patch<IDocumentRequest>(`/document/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};


const remove = (id: number) => {
    return http.delete<IDocumentRequest>(`/document/${id}`);

    if (isAuthorized('document.delete')) {
        return http.delete<IDocumentRequest>(`/document/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const DocumentService = {
    create,
    getAll,
    get,
    update,
    getFile,
    remove
}

export default DocumentService;