import { http } from "../http-common";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('quiz.list')) {
        return http.get<any>(`/students/me/quizzes`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('quiz.show')) {
        return http.get<any>(`/quiz/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const QuizService = {
    getAll,
    get,
}

export default QuizService;