import { Button, Col, Form, Input, Row, Image, Typography, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';

const { Title, Text } = Typography;
const { Content } = Layout;

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: { email: string }) => {
        setLoading(true);

        setEmail(values.email);

        let userData = {
            email: values.email,
        };

        const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/reset-password-requests';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrl,
            data: userData,
        };

        axios
            .request(config)
            .then((response: any) => {
                console.log(response);
                openNotification('success', 'Başarılı', 'E-Posta adresinizi kontrol ediniz.');
                // Yönlendirme ve e-postayı URL parametresi olarak ekleme
                navigate(`/forgot-password-confirm?email=${encodeURIComponent(values.email)}`);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                openNotification('error', 'Başarısız', 'E-Posta adresiniz doğru değil! Kontrol ederek tekrar deneyin.');
                setLoading(false);
            });
    };

    return (
        <>
            <Row justify={'center'}>
                <Col>
                    <Row justify={'center'} className='mb-4 mt-4'>
                        <Image preview={false} width={64} />
                    </Row>
                    <Row>
                        <Title level={2}>Şifremi Unuttum</Title>
                        <p>
                            Şifrenizi değiştirmek için kullandığınız e-posta adresini girin.
                            Böylece şifrenizi sıfırlamak için size bir bağlantı gönderebiliriz.
                        </p>
                    </Row>
                    <Form
                        name='email_form'
                        className='email-form'
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <p>E-Posta</p>
                        <Form.Item
                            name='email'
                            rules={[{ required: true, message: 'Lütfen E-posta giriniz!' }]}
                        >
                            <Input
                                size='large'
                                placeholder='E-Posta'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" loading={loading} size="large" className="w-full" type="primary">
                                Gönder
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row className=' mt-24' justify={'center'}>
                <Col>
                    <p className='fs-18 font-color-subtext fw-400'>
                        Şifreni biliyor musun? <Link className='font-color-dark-purple fw-400 fs-18' to={'/giris'}>Giriş Yap</Link>
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default ForgotPassword;