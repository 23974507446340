import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ExplanationService from '../services/ExplanationService';
import { IExplanationResponse } from '../types/Explanation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Button, Col, Row } from 'antd';

const Explanation = () => {
    const [loading, setLoading] = useState<boolean>();
    const [explanation, setExplanation] = useState<IExplanationResponse>()
    const navigate = useNavigate();
    const { id }: any = useParams();

    useEffect(() => {
        getExplanation()
    }, [])

    const getExplanation = () => {
        setLoading(true);
        ExplanationService.get(id)
            .then((response: any) => {
                let data: IExplanationResponse = response.data;
                setExplanation(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const modules = {
        toolbar: false  // Toolbar'ı kaldırmak için false kullanın
    };

    return (
        <>
            <Row justify={'end'}>
                <Col>
                    <Button onClick={() => navigate(-1)} className='fw-700'>
                        Geri
                    </Button>
                </Col>
            </Row>
            <h3>{explanation?.title}</h3>
            <ReactQuill modules={modules} value={explanation?.description} className='mb-24' theme="snow" readOnly />
        </>
    )
}

export default Explanation