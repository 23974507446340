import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/tr';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('tr');

const WeeklyCalendar: React.FC = () => {
    const [currentWeek, setCurrentWeek] = useState<Dayjs>(dayjs());

    const startOfWeek = currentWeek.startOf('week');
    const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
        startOfWeek.add(i, 'day')
    );

    const handleWeekChange = (newWeek: Dayjs) => {
        setCurrentWeek(newWeek);
        const startDate = newWeek.startOf('week').format('YYYY-MM-DD');
        const endDate = newWeek.endOf('week').format('YYYY-MM-DD');
        console.log(`Start Date: ${startDate}, End Date: ${endDate}`);
    };

    const prevWeek = () => {
        handleWeekChange(currentWeek.subtract(1, 'week'));
    };

    const nextWeek = () => {
        handleWeekChange(currentWeek.add(1, 'week'));
    };

    useEffect(() => {
        const startDate = startOfWeek.format('YYYY-MM-DD');
        const endDate = startOfWeek.endOf('week').format('YYYY-MM-DD');
        console.log(`Start Date: ${startDate}, End Date: ${endDate}`);
    }, [startOfWeek]);

    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <Button onClick={prevWeek}>&lt;</Button>
                </Col>
                <Col>
                    <h2>{startOfWeek.format('MMMM YYYY')}</h2>
                </Col>
                <Col>
                    <Button onClick={nextWeek}>&gt;</Button>
                </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: 10 }}>
                {daysOfWeek.map(day => {
                    const isToday = day.isSame(dayjs(), 'day');
                    return (
                        <Col key={day.format('DD-MM-YYYY')} className="day-column">
                            <div>
                                <div style={{
                                    textAlign: 'center',
                                    borderRadius: '50%',
                                    padding: '8px',
                                }}>{day.format('dd')}</div>
                                <div style={{
                                    backgroundColor: isToday ? '#F2F3FE' : 'transparent', textAlign: 'center',
                                    borderRadius: '50%',
                                    padding: '8px',
                                }}>{day.format('D')}</div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default WeeklyCalendar;
