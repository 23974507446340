import { Button, Divider, Drawer, Image } from 'antd'
import React, { useState } from 'react'
import StudentQuestionService from '../../services/StudentQuestionService';
import { formatDate } from '../../utils';

interface DetailButtonProps {
    record: any
}
const DetailButton: React.FC<DetailButtonProps> = ({ record }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [pdfUrlResponse, setPdfUrlResponse] = useState<string>('');

    const getFile = (fileName: string) => {
        StudentQuestionService.getFile(fileName)
            .then((response: any) => {
                const url = URL.createObjectURL(new Blob([response.data]));
                setPdfUrl(url);
            }).catch((e: Error) => {
                console.error('Dosya getirilirken hata oluştu:', e);
                setPdfUrl('');
            });
    };

    const getResponseFile = (fileName: string) => {
        StudentQuestionService.getFile(fileName)
            .then((response: any) => {
                const url = URL.createObjectURL(new Blob([response.data]));
                setPdfUrlResponse(url);
            }).catch((e: Error) => {
                console.error('Dosya getirilirken hata oluştu:', e);
                setPdfUrlResponse('');
            });
    };

    const showDrawer = (record: any) => {
        setSelectedQuestion(record);
        setDrawerVisible(true);
        if (record.image) {
            getFile(record.image);
        } else {
            setPdfUrl('');
        }
        if (record.responseImage) {
            getResponseFile(record.responseImage);
        } else {
            setPdfUrlResponse('');
        }
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedQuestion(null);
    };


    return (
        <div>
            <Button className='btn bg-saha-purple fw-700 font-color-white' onClick={() => showDrawer(record)}>Detay</Button>
            <Drawer
                title="Soru Detayları"
                width={520}
                onClose={closeDrawer}
                visible={drawerVisible}
            >
                <p><strong>Ders:</strong> {selectedQuestion?.lessonName}</p>
                <p><strong>Soru:</strong> {selectedQuestion?.question}</p>
                <p><strong>Oluşturulma Tarihi:</strong> {formatDate(selectedQuestion?.createdAt)}</p>
                {pdfUrl && <Image className='max-height-300' src={pdfUrl}  />}
                <Divider></Divider>
                <p><strong>Cevap:</strong> {selectedQuestion?.answer}</p>
                {pdfUrlResponse && <Image className='max-height-300' src={pdfUrlResponse} />}
            </Drawer>
        </div>
    )
}

export default DetailButton