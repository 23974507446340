import { Button, Image, Col, Input, Row, Form, GetProp, UploadProps, UploadFile, Upload } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/saha-logo-dark.png';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const Application = () => {
    const [cvFile, setCvFile] = useState<UploadFile[]>([]);
    const [diplomaFile, setDiplomaFile] = useState<UploadFile[]>([]);
    const navigate = useNavigate()

    const onFinish = (values: any) => {
        if (cvFile.length === 0) {
            openNotification('error', 'Başarısız', 'Lütfen CV dosyanızı yükleyin.');
            return;
        }

        if (diplomaFile.length === 0) {
            openNotification('error', 'Başarısız', 'Lütfen Diploma dosyanızı yükleyin.');
            return;
        }

        const formData = new FormData();
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        formData.append('phoneNumber', values.phone);
        formData.append('positionType', "TEACHER");
        formData.append('description', values.description);

        if (cvFile[0]) {
            formData.append('resumeFile', cvFile[0] as unknown as Blob, cvFile[0].name);
        }

        if (diplomaFile[0]) {
            formData.append('diplomaFile', diplomaFile[0] as unknown as Blob, diplomaFile[0].name);
        }

        const apiUrl = 'https://saha-admin-api.dact.studio/api/v1/applications';

        axios.post(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                openNotification('success', 'Başarılı', 'Başvuru başarıyla gönderildi.');
                navigate('/giris')
            })
            .catch(error => {
                openNotification('error', 'Hata', 'Başvuru gönderilirken bir hata oluştu.');
            });
    };

    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Yükle</div>
        </button>
    );

    const cvUploadProps: UploadProps = {
        onPreview: async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }
        },
        onRemove: (file) => {
            setCvFile([]);
        },
        beforeUpload: (file) => {
            if (cvFile.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet CV dosyası yükleyebilirsiniz');
                return Upload.LIST_IGNORE;
            }
            setCvFile([file]);
            return false;
        },
        fileList: cvFile,
        defaultFileList: cvFile,
        accept: '.jpg,.jpeg,.png',
        multiple: false,
    };

    const diplomaUploadProps: UploadProps = {
        onPreview: async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }
        },
        onRemove: (file) => {
            setDiplomaFile([]);
        },
        beforeUpload: (file) => {
            if (diplomaFile.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet diploma dosyası yükleyebilirsiniz');
                return Upload.LIST_IGNORE;
            }
            setDiplomaFile([file]);
            return false;
        },
        fileList: diplomaFile,
        defaultFileList: diplomaFile,
        accept: '.jpg,.jpeg,.png',
        multiple: false,
    };

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <h2 className='fs-36 fw-400 h-3 font-color-header'>Başvuru</h2>
                <p className='fs-20 fw-400 font-color-subtext'>Hoşgeldiniz. Lütfen bilgilerinizi doldurun.</p>
                <Row gutter={8}>
                    <Col span={12}>
                        <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>İsim</p>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'Lütfen İsminizi giriniz!' }]}
                        >
                            <Input size='large' className='form-input' placeholder="İsim" />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>Soyisim</p>
                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: 'Lütfen Soyisminizi giriniz!' }]}
                        >
                            <Input size='large' className='form-input' placeholder="Soyisim" />
                        </Form.Item>
                    </Col>
                </Row>
                <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>E-Posta</p>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Lütfen E-posta giriniz!' }]}
                >
                    <Input className='form-input' size='large' placeholder="E-Posta" />
                </Form.Item>
                <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>Telefon</p>
                <Form.Item
                    name="phone"
                    rules={[{ required: true, message: 'Lütfen telefon numaranızı giriniz!' }, { pattern: /^[0-9]{10}$/, message: 'Lütfen geçerli bir telefon numarası giriniz!' }]}
                >
                    <Input prefix={'+90'} className='form-input' size='large' placeholder="Telefon" />
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>CV</p>
                        <Form.Item name="resumeFile">
                            <Upload {...cvUploadProps} listType="picture-card">
                                {cvFile.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>Diploma</p>
                        <Form.Item name="diplomaFile">
                            <Upload {...diplomaUploadProps} listType="picture-card">
                                {diplomaFile.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <p className='fs-16 fw-400 font-color-header  mt-0 mb-4'>Özet Bilgi</p>
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Kendiniz hakkında özet bilgi verin.' }]}
                >
                    <TextArea className='form-input h-250' size='large' />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" className='w-full' type="primary" size="large">
                        Başvuruyu Gönder
                    </Button>
                </Form.Item>
            </Form>
            <Row justify={'center'}>
                <Col>
                    <p >
                        Hesabınız Var mı? <Link to={'/giris'}>Giriş Yap</Link>
                    </p>
                </Col>
            </Row>
  
        </>
    )
}

export default Application