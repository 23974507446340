import { http } from "../http-common";
import { IPackageResponse } from "../types/Package";
import { isAuthorized, openNotification } from "../utils";

const getSaleList = () => {
    return http.get<IPackageResponse>(`/students/course`);
};

const getPurchasedList = () => {
    return http.get<IPackageResponse>(`/students/purchased-course`);
};

const getDetail = (id: any) => {
        return http.get<IPackageResponse>(`/students/purchased-course/${id}`);
};

const get = (id: any) => {
    return http.get<IPackageResponse>(`/students/course/${id}`);
};

const CourseService = {
    getSaleList,
    getPurchasedList,
    getDetail,
    get
}

export default CourseService;