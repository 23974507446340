import { Card, Col, Collapse, Row, Space, Typography } from 'antd'
import React from 'react'
import { FilePdfOutlined, FileOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink, useParams } from 'react-router-dom';
import LearnUnitDetail from './LearnUnitDetail';
import { calculateLessonTotalDuration, calculateLessonTotalVideos, formatDuration } from '../../utils';

interface LearnLessonDetailProps {
    lesson: any;
}

const LearnLessonDetail: React.FC<LearnLessonDetailProps> = ({ lesson }) => {
    const { Title, Text } = Typography;
    const { id, subjectId, courseId, lessonId }: any = useParams();


    const lessonTitleRender = (lesson: any) => {
        let unitTotalDuration = calculateLessonTotalDuration(lesson);
        let totalVideo = calculateLessonTotalVideos(lesson);
        return <Space direction="vertical">
            <Title className='m-0' level={5}>{lesson.lessonName}</Title>
            <span>
                {/* <Text type="secondary">/{totalVideo} - </Text> */}
                {/* <Text type="secondary">{formatDuration(unitTotalDuration)}</Text> */}
            </span>
        </Space>
    }

    return (
        <Collapse
            bordered={false}
            accordion
            expandIconPosition="right"
            defaultActiveKey={[lessonId]}
            expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
            style={{ padding: '0px', borderRadius: 0 }}
            items={[{
                key: lesson.lessonId, label: <>{lessonTitleRender(lesson)}</>, children:
                    <>
                        {lesson.units?.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((unit: any, index:number) => (
                            <LearnUnitDetail index={index} unit={unit}></LearnUnitDetail>
                        ))}
                    </>
            }]}
        />
    )
}

export default LearnLessonDetail