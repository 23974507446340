import { Button, Card, Col, Row } from 'antd'
import { useEffect, useState } from 'react';
import UserPackageService from '../../services/UserPackagesService';
import MyPackagesItemCard from '../Packages/MyPackagesItemCard';
import CourseCard from './CourseCard';
import { NavLink } from 'react-router-dom';
import CourseService from '../../services/CourseService';


const ActiveCourse = () => {
    const [loading, setLoading] = useState<boolean>();
    const [courses, setCourses] = useState<any>();

    useEffect(() => {
        getMyPackages()
    }, [])

    const getMyPackages = async () => {
        setLoading(true);
        try {
            const response = await CourseService.getPurchasedList();
            const data = Array.isArray(response?.data) ? response?.data : (response?.data ? [response.data] : []);
            setCourses(data);
        } catch (error) {
            console.error("Failed to fetch courses:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card className='pt-0' loading={loading} title="Kurslarım"
                extra={<NavLink to="/my-course"><Button type="text">Tümünü Gör</Button></NavLink>} >
                <Row gutter={[24, 24]}>
                    {courses?.map((packageItem: any) => (
                        <Col xl={8} md={12} xs={24}>
                            <CourseCard packageItem={packageItem} />
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    )
}

export default ActiveCourse