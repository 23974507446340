import { Button, Drawer, Form, Input, notification } from 'antd';
import React from 'react'
import NoteService from '../Service/NoteService';

interface CreateNoteProps {
    entityId: any;
    entityName: any;
    onClose: any;
    createVisible: any;
    refreshTrigger: any;
}
const CreateNote:React.FC<CreateNoteProps> = ({entityId, entityName, onClose, createVisible, refreshTrigger}) => {
    const [form] = Form.useForm();
    
    function handleCreateSubmit(values: any): void {
        let data = {
            entityId: entityId,
            entityName: entityName,
            content: values.content,
        };

        NoteService.create(data)
            .then(() => {
                refreshTrigger();
                form.resetFields();
                notification.success({
                    message: 'Başarılı',
                    description: 'Not başarıyla eklendi.',
                });
                onClose();
            })
            .catch((e: Error) => {
                notification.error({
                    message: 'Hata',
                    description: 'Kaydetme sırasında bir hata oluştu.',
                });
                console.error('Kaydetme sırasında hata:', e);
            });
    }

    return (
        <div>
            <Drawer
                title="Yeni Not Oluştur"
                placement="right"
                onClose={onClose}
                visible={createVisible}
                width={400}
            >
                <Form layout="vertical" form={form} onFinish={handleCreateSubmit}>
                    <Form.Item name="content" label="Not" rules={[{ required: true, message: 'Lütfen Notunuzu girin' }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

export default CreateNote