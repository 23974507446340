import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import { useLocation } from 'react-router-dom';

interface VimeoPlayerProps {
  videoId: any;
  onTimeUpdate?: (seconds: number) => void; // Optional callback for time updates
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ videoId, onTimeUpdate }) => {
  const playerRef = useRef<HTMLIFrameElement>(null);
  const lastSavedTimeRef = useRef<number>(0);
  const seconds = 3;
  const location = useLocation();

  useEffect(() => {
    if (!playerRef.current) return;

    const player = new Player(playerRef.current);

    const handleTimeUpdate = (data: { seconds: number }) => {
      const currentTime = data.seconds;

      if (currentTime - lastSavedTimeRef.current >= seconds) {
        if (onTimeUpdate) {
          onTimeUpdate(currentTime);
        }
        lastSavedTimeRef.current = currentTime;
      }

      console.log(`Current time: ${currentTime} seconds`);
    };

    player.on('timeupdate', handleTimeUpdate);

    // URL'deki hash kısmını al ve duration parametresini ayıkla
    const hash = location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1)); // '#' karakterini çıkar
      const durationParam = params.get('duration');
      if (durationParam) {
        const duration = parseInt(durationParam, 10);
        player.ready().then(() => {
          player.setCurrentTime(duration).catch(error => {
            console.error('Error setting video time:', error);
          });
        });
      }
    }

    // Cleanup function
    return () => {
      player.off('timeupdate', handleTimeUpdate);
      player.unload(); // Unload the player when the component is unmounted or videoId changes
    };
  }, [videoId, onTimeUpdate, location.hash]); // Add videoId, onTimeUpdate, and location.hash as dependencies

  return (
    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        ref={playerRef}
        src={`https://player.vimeo.com/video/${videoId}?badge=0&autopause=0&player_id=0&app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        title="Vimeo Video Player"
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;
