import {http} from "../http-common";


const createBillingAddress = (data: any) => {
    return http.post<any>(`/bills`, data);
};

const createPayment = (data: any) => {
    return http.post<any>(`/students/me/packages/initialize-purchase`, data);
};

const purchase = (data: any) => {
    return http.post<any>(`/students/me/course/initialize-purchase`, data);
};

const PaymentService = {
    createBillingAddress,
    createPayment,
    purchase
}

export default PaymentService;