import { http } from "../http-common";
import { ILiveLessonRequest, ILiveLessonResponse } from "../types/P2PLesson";
import { isAuthorized, openNotification } from "../utils";

const create = (data: any) => {
    if (isAuthorized('livelesson.create')) {
        return http.post<any>(`/students/live-lesson`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAll = (data: any) => {
    if (isAuthorized('livelesson.list')) {
        return http.get<ILiveLessonResponse>(`/live-lessons/me${data}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('livelesson.show')) {
        return http.get<ILiveLessonResponse>(`/live-lessons/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ILiveLessonRequest) => {
    if (isAuthorized('livelesson.update')) {
        return http.patch<ILiveLessonRequest>(`/live-lessons/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('livelesson.delete')) {
        return http.delete<ILiveLessonRequest>(`/students/live-lesson/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const LiveLessonService = {
    create,
    get,
    remove,
    update,
    getAll
}

export default LiveLessonService;