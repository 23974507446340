// ProfileLink.tsx
import { Avatar, Tooltip } from 'antd';
import React from 'react';

interface Profile {
  id: number;
  fileName: string;
  title: string;
  backgroundColor: string;
}

const profiles: Profile[] = [
  { id: 1, fileName: 'albert-einstein.png', title: 'Albert Einstein', backgroundColor: '#E4CA00' },
  { id: 2, fileName: 'batlamyus.png', title: 'Batlamyus', backgroundColor: '#FF9E00' },
  { id: 3, fileName: 'herodot.png', title: 'Herodot', backgroundColor: '#A96E00' },
  { id: 4, fileName: 'immanuel-kant.png', title: 'Immanuel Kant', backgroundColor: '#FF94B6' },
  { id: 5, fileName: 'isaac-newton.png', title: 'Isaac Newton', backgroundColor: '#005BA1' },
  { id: 6, fileName: 'marie-curie.png', title: 'Marie Curie', backgroundColor: '#E8E2F6' },
  { id: 7, fileName: 'niels-bohr.png', title: 'Niels Bohr', backgroundColor: '#4191E1' },
  { id: 8, fileName: 'platon.png', title: 'Platon', backgroundColor: '#EE9400' },
  { id: 9, fileName: 'rene-descartes.png', title: 'Rene Descartes', backgroundColor: '#A35947' },
  { id: 10, fileName: 'richard-feynman.png', title: 'Richard Feynman', backgroundColor: '#F67D5F' },
  { id: 11, fileName: 'robert-oppenheimer.png', title: 'Robert Oppenheimer', backgroundColor: '#FD8B49' },
  { id: 12, fileName: 'rosalind-franklin.png', title: 'Rosalind Franklin', backgroundColor: '#F25758' },
  { id: 13, fileName: 'socrates.png', title: 'Socrates', backgroundColor: '#FD5B5A' },
  { id: 14, fileName: 'thales.png', title: 'Thales', backgroundColor: '#D3A43A' },
  { id: 15, fileName: 'william-shakespeare.png', title: 'William Shakespeare', backgroundColor: '#32A9C9' },
  { id: 16, fileName: 'chien-shiung-wu.png', title: 'Chien-Shiung Wu', backgroundColor: '#82869b' },
  { id: 17, fileName: 'ada-lovelace.png', title: 'Ada Lovelace', backgroundColor: '#e9d7c9' },
  { id: 18, fileName: 'vera-rubin.png', title: 'Vera Rubin', backgroundColor: '#5f787d' },
  { id: 19, fileName: 'katherine-johnson.png', title: 'Katherine Johnson', backgroundColor: '#3d94a3' },
  { id: 20, fileName: 'jennifer-doudna.png', title: 'Jennifer Doudna', backgroundColor: '#373e4d' },
  { id: 21, fileName: 'emmanuelle-charpentier.png', title: 'Emmanuelle Charpentier', backgroundColor: '#c73c30' },
  { id: 22, fileName: 'lise-meitner.png', title: 'Lise Meitner', backgroundColor: '#abbf2e' },
];

const ProfileLink: React.FC = () => {
  const firstName: string | null = localStorage.getItem('firstName');
  const profilePictureId = localStorage.getItem('profilePicture');

  // Find the profile using the ID from local storage
  const profile = profilePictureId
    ? profiles.find((p) => p.id === parseInt(profilePictureId))
    : null;

  return (
    <Tooltip title="Profil">
      <Avatar
        shape="square"
        size={40}
        style={{ backgroundColor: profile ? profile.backgroundColor : '#935ac0' }} // Use profile background color if available
        src={profile ? require(`../../assets/avatar/${profile.fileName}`) : undefined} // Show profile picture if available
      >
        {!profile && firstName?.charAt(0)} {/* Show initial if no profile picture */}
      </Avatar>
    </Tooltip>

  );
};

export default ProfileLink;
