import React, { useEffect, useState } from 'react';
import { Button, Input, Spin, Table, message, Popconfirm, Row, Col, Upload, List, Modal, Divider } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UploadOutlined, FileOutlined, PictureOutlined } from '@ant-design/icons';
import { TicketResponse } from './Type/Ticket';
import { getTicketResponses, createTicketResponse, updateTicketResponse, deleteTicketResponse, uploadTicketResponseAttachment, getTicketResponseAttachment, getTicketDetails } from './Service/TicketService';

interface TicketDetailProps {
  ticketId: number;
  onClose: () => void;
}

const TicketDetail: React.FC<TicketDetailProps> = ({ ticketId, onClose }) => {
  const [responses, setResponses] = useState<any>([]);
  const [currentResponse, setCurrentResponse] = useState<TicketResponse | null>(null);
  const [responseText, setResponseText] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageOriginalUrl, setImageOriginalUrl] = useState<any>(null);

  useEffect(() => {
    if (ticketId) {
      fetchResponses(ticketId);
    }
  }, [ticketId]);

  const fetchResponses = async (ticketId: number) => {
    setLoading(true);
    try {
      const response: any = await getTicketDetails(ticketId);
      setResponses(response.responses.reverse());
    } finally {
      setLoading(false);
    }
  };

  const handleAddResponse = async () => {
    if (!responseText.trim()) {
      message.error('Yanıt metni boş olamaz');
      return;
    }

    const newResponse: any = {
      responseText,
      ticketId,
    };
    setLoading(true);
    try {
      const createdResponse: any = await createTicketResponse(ticketId, newResponse);
      if (fileList.length > 0) {
        const uploadPromises = fileList.map(file => uploadTicketResponseAttachment(createdResponse, file.originFileObj));
        await Promise.all(uploadPromises);
      }
      setResponseText('');
      setFileList([]);
      await fetchResponses(ticketId);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateResponse = async () => {
    if (!currentResponse) return;
    if (!responseText.trim()) {
      message.error('Yanıt metni boş olamaz');
      return;
    }

    const updatedResponse = { ...currentResponse, responseText, ticketId };
    setLoading(true);
    try {
      await updateTicketResponse(ticketId, updatedResponse);
      setCurrentResponse(null);
      setResponseText('');
      await fetchResponses(ticketId);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteResponse = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicketResponse(id);
      await fetchResponses(ticketId);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = ({ fileList }: any) => {
    const filteredFiles = fileList.filter((file: any) => {
      if (file.size / 1024 / 1024 > 1) {
        message.error(`${file.name} dosyası 1MB boyutunu aşıyor.`);
        return false;
      }
      return true;
    });
    setFileList(filteredFiles);
  };

  const handleAttachmentClick = async (attachmentId: number) => {
    try {
      const attachmentResponse = await getTicketResponseAttachment(attachmentId);
      const preSignUrl = attachmentResponse.preSignFileUrl;

      const fileType = preSignUrl.split('?')[0].split('.').pop()?.toLowerCase();
      const isImage = fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif';
      if (isImage) {
        // Eğer dosya bir resimse
        setImageSrc(preSignUrl); // Modalda gösterilecek resim
        setImageOriginalUrl(preSignUrl); // "Gerçeğini Aç" butonuyla açılacak URL
        setIsImageModalVisible(true); // Modalı aç
      } else {
        // Diğer dosya türlerini doğrudan indir
        const link = document.createElement('a');
        link.href = preSignUrl;
        link.setAttribute('download', 'file');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      message.error('Dosya alınırken hata oluştu.');
    }
  };

  const columns = [
    {
      title: 'Yanıt Metni',
      dataIndex: 'responseText',
      key: 'responseText',
    },
    {
      title: 'Ad Soyad',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Dosyalar',
      dataIndex: 'ticketResponseAttachments',
      key: 'ticketResponseAttachments',
      render: (attachments: any[]) => (
        attachments && attachments.length > 0 ? (
          <div
            style={{
              whiteSpace: 'nowrap',   
              overflowX: 'auto',      
              display: 'flex',       
              maxWidth: '150px',    
            }}
          >
            {attachments.map((attachment: any) => {
              const fileType = attachment.filePath.split('.').pop()?.toLowerCase();
              const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileType);
              return (
                <div key={attachment.id} style={{ marginRight: 8 }}>
                  <Button type="link" onClick={() => handleAttachmentClick(attachment.id)}>
                    {isImage ? <PictureOutlined /> : <FileOutlined />}
                  </Button>
                </div>
              );
            })}
          </div>
        ) : null
      ),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_: any, record: TicketResponse) => (
        <Popconfirm
          title="Bu yanıtı silmek istediğinize emin misiniz?"
          onConfirm={() => handleDeleteResponse(record.id)}
          okText="Evet"
          cancelText="Hayır"
        >
          <Button icon={<DeleteOutlined />} danger style={{ border: 0 }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className='ticket-detail-container'>

      <Spin spinning={loading}>
        <Row justify="space-between" gutter={[16, 16]} style={{ margin: "16px 0px" }}>
          <Col span={24}>
            <Input
              value={responseText}
              onChange={(e) => setResponseText(e.target.value)}
              placeholder="Yanıt metnini girin"
            />
          </Col>

        </Row>
        <Row justify="space-between" gutter={[16, 16]} style={{ margin: "16px 8px" }}>
          <div style={{ width: '75%' }}>
            <Upload
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleUploadChange}
            >
              <Button icon={<UploadOutlined />}>Dosya Yükle</Button>
            </Upload>
          </div>
          {currentResponse ? (
            <Button icon={<EditOutlined />} onClick={handleUpdateResponse}>Yanıtı Güncelle</Button>
          ) : (
            <Button style={{ backgroundColor: '#000' }} type='primary' onClick={handleAddResponse}>Gönder</Button>
          )}
        </Row>
        <Divider >Yanıt Geçmişi</Divider>
        <Table
          dataSource={responses}
          columns={columns}
          rowKey="id"
        />
        <Modal
          visible={isImageModalVisible}
          title="Dosya"
          footer={[
            <Button key="viewOriginal" type="primary" onClick={() => window.open(imageOriginalUrl ?? '', '_blank')}>
              İndir
            </Button>,
            <Button key="close" onClick={() => setIsImageModalVisible(false)}>
              Kapat
            </Button>
          ]}
          onCancel={() => setIsImageModalVisible(false)}
        >
          <Row justify="center">
            <img src={imageSrc ?? undefined} alt="Attachment" style={{ maxHeight: 400 }} />
          </Row>
        </Modal>
      </Spin>
    </div>
  );
};

export default TicketDetail;