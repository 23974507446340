import React, { useEffect, useState } from 'react';
import { Tooltip, Card, Timeline, Row, Col } from 'antd';
import { FormOutlined, FileDoneOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/tr';  // Optional: Import locale for Turkish if needed
import AccountHistoryService from '../../services/AccountHistoryService';
import { NavLink } from 'react-router-dom';

dayjs.extend(relativeTime);
dayjs.locale('tr'); // Optional: Set locale to Turkish

const iconMap: { [key: string]: React.ReactNode } = {
  Assignment: <FileDoneOutlined style={{ fontSize: '16px' }} />,
  StudentQuestion: <QuestionCircleOutlined style={{ fontSize: '16px' }} />,
  LiveLesson: <LaptopOutlined style={{ fontSize: '16px' }} />,
  VideoHistory: <PlaySquareOutlined style={{ fontSize: '16px' }} />,
};

const textMap: { [key: string]: string } = {
  Assignment: 'Ödev Oluşturuldu',
  StudentQuestion: 'Soru Sordu',
  LiveLesson: 'Canlı Ders Oluşturdu',
  VideoHistory: 'Video',
};

const TimelineActivity: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    AccountHistoryService.getAll()
      .then((response: any) => {
        const data = response.data;
        setData(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const timelineItems = data.map((item: any) => {
    const icon = iconMap[item.entityName] || <FormOutlined style={{ fontSize: '16px' }} />; // Default icon if none found
    const text = textMap[item.entityName] || 'Etkinlik'; // Default text if none found
    const createdAt = dayjs(item.createdAt);
    const relativeTime = createdAt.fromNow();  // 5 minutes ago, etc.
    const fullTime = createdAt.format('DD/MM/YYYY HH:mm');  // Full date in 24-hour format
    const itemData = JSON.parse(item.newValue);
    console.log('itemData', itemData);

    let itemRender = <Row justify={"space-between"}>
      <Col>{text}</Col>
      <Col>
        <Tooltip title={fullTime}>
          <i style={{ color: '#8c8c8c', fontSize: 12 }}>{relativeTime}</i>
        </Tooltip>
      </Col>
    </Row>;

    switch (item.entityName) {
      case "VideoHistory":
        if (itemData?.course?.id) {
          itemRender = <NavLink style={{ color: '#03020A' }} target='_blank' to={`/video/${itemData?.video?.id}/subject/${itemData?.subject?.id}/course/${itemData?.course?.id}/unit/${itemData?.subject?.unit?.id}/lesson/${itemData?.subject?.unit?.lesson?.id}#duration=${itemData?.duration}`}>
            <Row gutter={[24, 0]} justify={"space-between"}>
              <Col>{text} - {itemData?.video?.title}</Col>
              <Col>
                <Tooltip title={fullTime}>
                  <i style={{ color: '#8c8c8c', fontSize: 12 }}>{relativeTime}</i>
                </Tooltip>
              </Col>
            </Row>
          </NavLink>
        }
        break;
      default:
        break;
    }

    return {
      // label: (
      //   <Tooltip title={fullTime}>
      //     <span>{relativeTime}</span>
      //   </Tooltip>
      // ),
      dot: icon,
      color: '#6324F5',
      children: (itemRender),
    };
  });

  return (
    <Card title="Son Aktivitelerim" bodyStyle={{ marginBottom: 0, paddingBottom: 0, maxHeight: 305, overflow: "auto" }}>
      <Timeline mode={'left'} items={timelineItems} />
    </Card>
  );
};

export default TimelineActivity;
