import {http} from "../http-common";
import {IExplanationResponse } from "../types/Explanation";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('explanation.list')) {
        return http.get<IExplanationResponse>(`/explanation`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('explanation.show')) {
        return http.get<IExplanationResponse>(`/explanation/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const ExplanationService = {
    getAll,
    get,
}

export default ExplanationService;