import { notification } from 'antd';
import dayjs from 'dayjs';

interface Video {
    id: number;
    title: string;
    link: string | null;
    description: string | null;
    cover: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    subjectId: number;
    vimeoId: number;
    duration: number;
    isCompleted?: boolean;
    watchedDuration?: number;
}

interface Subject {
    id: number;
    title: string;
    description: string | null;
    unitId: number;
    unitName: string;
    lessonId: number;
    lessonName: string;
    unitSortOrder: number;
    sortOrder: number;
    gradeId: number;
    gradeName: string;
    videos: Video[];
    documents: any[];
    explanations: any[];
    quizzes: any[];
    questions: any[];
    totalDuration: number | null;
    watchedDuration: number | null;
}

interface Unit {
    unitId: number;
    unitName: string;
    sortOrder: number;
    subjects: Subject[];
}

interface Lesson {
    lessonName: string;
    lessonId: number;
    units: Unit[];
}

interface Grade {
    gradeName: string;
    gradeId: number;
    lessons: Lesson[];
}

export const extractVideoList = (transformedData: Grade[]): any[] => {
    const videoList: any[] = [];
    
    transformedData.forEach((grade) => {
        grade.lessons.forEach((lesson) => {
            lesson.units.forEach((unit) => {
                unit.subjects.forEach((subject) => {
                    subject.videos.forEach((video) => {
                        videoList.push({
                            ...video,
                            gradeId: grade.gradeId,
                            gradeName: grade.gradeName,
                            lessonId: lesson.lessonId,
                            lessonName: lesson.lessonName,
                            unitId: unit.unitId,
                            unitName: unit.unitName,
                            subjectId: subject.id,
                            subjectTitle: subject.title
                        });
                    });
                });
            });
        });
    });

    return videoList;
};


export const transformData = (data: Subject[], videos: any[]): Grade[] => {
    const groupedData: { [key: number]: Grade } = {};

    data.forEach((item) => {
        const gradeId = item.gradeId;
        const lessonId = item.lessonId;
        const unitId = item.unitId;

        if (!groupedData[gradeId]) {
            groupedData[gradeId] = {
                gradeId: gradeId,
                gradeName: item.gradeName,
                lessons: []
            };
        }

        const grade = groupedData[gradeId];
        let lesson = grade.lessons.find((lesson: Lesson) => lesson.lessonId === lessonId);

        if (!lesson) {
            lesson = {
                lessonId: lessonId,
                lessonName: item.lessonName,
                units: []
            };
            grade.lessons.push(lesson);
        }

        let unit = lesson.units.find((unit: Unit) => unit.unitId === unitId);

        if (!unit) {
            unit = {
                unitId: unitId,
                unitName: item.unitName,
                sortOrder: item.unitSortOrder,
                subjects: []
            };
            lesson.units.push(unit);
        }

        const subjectData: Subject = {
            id: item.id,
            title: item.title,
            unitId: item.unitId,
            unitName: item.unitName,
            lessonId: item.lessonId,
            lessonName: item.lessonName,
            gradeId: item.gradeId,
            gradeName: item.gradeName,
            description: item.description,
            sortOrder: item.sortOrder,
            unitSortOrder: item.unitSortOrder,
            videos: item.videos.map(video => {
                const matchedVideo = videos.find(v => v.videoId === video.id);
                return {
                    ...video,
                    isCompleted: matchedVideo?.isCompleted ?? false,
                    watchedDuration: matchedVideo?.watchedDuration ?? 0,
                };
            }),
            documents: item.documents,
            explanations: item.explanations,
            quizzes: item.quizzes,
            questions: item.questions,
            totalDuration: item.totalDuration,
            watchedDuration: item.watchedDuration
        };

        unit.subjects.push(subjectData);
    });

    return Object.values(groupedData);
};



type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const openNotification = (type: NotificationType, status: string, description: string) => {
    const args = {
        message: status,
        description: description,
        duration: 3,
    };
    notification[type](args);
};

export function isAuthorized(requiredPermission: any) {
    let permissions = localStorage.getItem('permissions')
    return permissions?.includes(requiredPermission);
}

export function isFutureDate(dateString: string): boolean {
    const inputDate = dayjs(dateString);
    const now = dayjs();

    // Gelen tarih ve saat şimdiki zamandan büyük mü kontrol et
    return inputDate.isAfter(now);
}

export function formatTime(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('HH:mm');
}

export function formatDateString(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
}

export function formatDate(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function getDateRangeDefault(): any {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-90, 'd'),
        dayjs()
    ];
    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRange() {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-7, 'd'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRangeNew() {

    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().startOf('month'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function convertDateFormat(dateStr: any): string {
    return dayjs(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
}


export function turkishToEnglish(input: string): string {
    const turkishChars: Record<string, string> = {
        'Ç': 'C',
        'Ş': 'S',
        'Ğ': 'G',
        'Ü': 'U',
        'İ': 'I',
        'Ö': 'O',
        'ç': 'c',
        'ş': 's',
        'ğ': 'g',
        'ü': 'u',
        'ı': 'i',
        'ö': 'o'
    };

    return input.split('').map(char => turkishChars[char] || char).join('');
}


export function formatNumber(value:number) {
    if(value){
        return value.toLocaleString('tr-TR');
    }
}

export const isValidUrlPattern = (pattern: RegExp): boolean => {
    const url = window.location.href;
    const path = new URL(url).pathname;
    return pattern.test(path);
};



export function formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes} dk ${seconds} sn`;
}

export function calculateUnitTotalDuration(unit: Unit): number {
    return unit.subjects.reduce((totalDuration, subject) => {
        const subjectDuration = subject.videos.reduce((sum, video) => sum + video.duration, 0);
        return totalDuration + subjectDuration;
    }, 0);
}

export function calculateUnitTotalVideos(unit: Unit): number {
    return unit.subjects.reduce((totalVideos, subject) => {
        return totalVideos + subject.videos.length;
    }, 0);
}

export function calculateLessonTotalDuration(lesson: Lesson): number {
    return lesson.units.reduce((totalDuration, unit) => {
        const unitDuration = unit.subjects.reduce((subjectTotal, subject) => {
            const subjectDuration = subject.videos.reduce((videoTotal, video) => videoTotal + video.duration, 0);
            return subjectTotal + subjectDuration;
        }, 0);
        return totalDuration + unitDuration;
    }, 0);
}

export function calculateLessonTotalVideos(lesson: Lesson): number {
    return lesson.units.reduce((totalVideos, unit) => {
        const unitVideos = unit.subjects.reduce((subjectTotal, subject) => {
            return subjectTotal + subject.videos.length;
        }, 0);
        return totalVideos + unitVideos;
    }, 0);
}