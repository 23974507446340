import { Badge, Button, Card, Col, Progress, Row, Space, Tooltip, Typography } from 'antd'
import { ReactComponent as CheckIcon } from '../../assets/Packages/check-icon.svg';
import { ReactComponent as DocIcon } from '../../assets/icons/course/doc.svg';
import { ReactComponent as ExplanationIcon } from '../../assets/icons/course/explanation.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/course/question.svg';
import { ReactComponent as VideoIcon } from '../../assets/icons/course/video.svg';
import React, { useEffect, useState } from 'react'
import PaymentModal from '../Packages/PaymentModal'
import { formatNumber } from '../../utils';
import CourseCover from '../../assets/course-cover.svg'
import { NavLink } from 'react-router-dom';

interface PackageItemCardProps {
    course: any
    setBuyer: any
}

const PackageItemCard: React.FC<PackageItemCardProps> = ({ course, setBuyer }) => {

    const { Title, Text } = Typography;
    const [totalVideo, setTotalVideo] = useState(0);
    const [totalDocument, setTotalDocument] = useState(0);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [totalSubject, setTotalSubject] = useState(0);

    useEffect(() => {
        if (course?.subjects?.length > 0) {
            const totalVideo = course?.totalVideo;
            const totalDocument = course?.totalDocument;
            const totalQuestion = course?.totalQuestion;
            setTotalVideo(totalVideo);
            setTotalDocument(totalDocument);
            setTotalQuestion(totalQuestion);
            setTotalSubject(course?.subjects.length);
        }
    }, [])

    return (
        <>
            <Card
                bodyStyle={{ padding: 16, height: '100%', paddingTop: 0, flex: '1 1 auto' }}
                className='bg-blur border-none'
                cover={
                    <img
                        src={CourseCover}
                    />
                }
                actions={
                    [<Row justify={"space-between"} gutter={[16, 16]} style={{margin: '0px 8px'}}>
                        <Col span={12}>
                            <NavLink to={`/course/${course.id}`}>
                                <Button className='w-full' size="large" type="default">Detay</Button>
                            </NavLink>
                        </Col>
                        <Col span={12}>
                            <PaymentModal packageItem={course} setBuyer={setBuyer} />
                        </Col>
                    </Row>]
                }
            >
                <Title level={4}>{course?.name}</Title>
                <Row justify={"start"} gutter={[8, 8]} align={"bottom"}>
                    <Col>
                        <Title className='m-0' level={4}>{formatNumber(course.salePrice)} ₺</Title>
                    </Col>
                    <Col>
                        <Text className='text-red' delete>{formatNumber(course.listPrice)} ₺</Text>
                    </Col>
                </Row>
                <Row style={{ minHeight: '100%' }}>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: course?.subhead }} />
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default PackageItemCard