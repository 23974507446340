import React, { useState } from 'react';
import { Modal, Input, Radio, Button, Select, Row, Col, Form } from 'antd';
import { formatNumber } from '../../utils';
import CourseCover from '../../assets/course-cover.svg'


const { Option } = Select;
const { useForm } = Form;

const PaymentModal: React.FC<any> = ({ packageItem, setBuyer }) => {
    const [invoiceType, setInvoiceType] = useState('personal');
    const [type, setType] = useState(true);
    const [form] = useForm();
    const [visible, setVisible] = useState(false);
    console.log(packageItem);

    const [allCities, setAllCities] = useState([
        'Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
        'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
        'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
        'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
        'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya',
        'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
        'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
        'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
        'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'
    ]);

    let authId = localStorage.getItem('id');

    const handleInvoiceTypeChange = (e: any) => {
        setInvoiceType(e.target.value);
        setType(e.target.value === 'personal');
    };

    const handlePaymentSubmit = (values: any) => {
        console.log(values);
        let postObj: any;

        if (type) {
            postObj = {
                "name": values.firstName,
                "surname": values.lastName,
                "identityNumber": values.idNumber,
                "billingAddress": values.billingAddress,
                "packageId": packageItem.id,
                "city": values.city,
                "phone": values.phone,
                "email": values.email,
            };
        } else {
            postObj = {
                "name": values.firstName,
                "surname": values.lastName,
                "taxNumber": values.taxNumber,
                "taxOffice": values.taxOffice,
                "companyName": values.companyName,
                "billingAddress": values.billingAddress,
                "packageId": packageItem.id,
                "city": values.city,
                "phone": values.phone,
                "email": values.email,
            };
        }

        setBuyer(postObj);
        setTimeout(() => {
            handleClose();
        }, 2000);

    };

    const handleClose = () => {
        setVisible(false);
    };

    // Function to normalize Turkish characters for search
    const normalizeString = (str: string) => {
        return str
            .toLowerCase()
            .replace(/ç/g, 'c')
            .replace(/ğ/g, 'g')
            .replace(/ı/g, 'i')
            .replace(/ö/g, 'o')
            .replace(/ş/g, 's')
            .replace(/ü/g, 'u');
    };

    const filterCity = (input: string, option: any) => {
        return normalizeString(option.children).includes(normalizeString(input));
    };

    return (
        <>
            <Button className='w-full' type="primary" size="large" onClick={() => setVisible(true)}>
                Hemen Al
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                width={768}
                className="payment-modal"
                footer={[]}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col>
                        <img src={CourseCover} style={{ height: 120, width: 120, borderRadius: 10 }} />
                    </Col>
                    <Col>
                        <div style={{ marginLeft: 16, flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: 24, fontWeight: 700 }}>{packageItem.name} </span>
                                <span style={{ fontSize: 36, fontWeight: 700 }}>{formatNumber(packageItem.salePrice)} ₺</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form form={form} layout="vertical" onFinish={handlePaymentSubmit}>

                    <Form.Item label={<span style={{ fontWeight: 'bold' }}>Faturalandırma Seçenekleri</span>}>
                        <Radio.Group
                            defaultValue="personal"
                            onChange={handleInvoiceTypeChange}
                            value={invoiceType}
                            className="invoice-type-radio-group"
                        >
                            <Radio value="personal" className='invoice-radio-button' style={{ marginRight: 16 }}>Bireysel Fatura</Radio>
                            <Radio value="corporate" className='invoice-radio-button'>Kurumsal Fatura</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {invoiceType === 'personal' && (
                        <div className="personal-invoice-form">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Ad"
                                        name="firstName"
                                        rules={[
                                            { required: true, message: 'Lütfen adınızı giriniz' },
                                            { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Adınız" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Soyad"
                                        name="lastName"
                                        rules={[
                                            { required: true, message: 'Lütfen soyadınızı giriniz' },
                                            { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Soyadınız" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="TC Kimlik"
                                        name="idNumber"
                                        rules={[
                                            { required: true, message: 'Lütfen TC Kimlik numaranız giriniz' },
                                            { pattern: /^\d{11}$/, message: 'TC Kimlik numaranız 11 haneli ve rakam olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="TC Kimlik numaranız" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Cep Telefonu"
                                        name="phone"
                                        rules={[
                                            { required: true, message: 'Lütfen telefon numaranızı giriniz' },
                                            { pattern: /^\d{10}$/, message: 'Telefon numarası 10 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Telefon Numarası" maxLength={11} className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="E-Posta"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Lütfen E-postanızı giriniz' },
                                            { type: 'email', message: 'Lütfen geçerli bir E-posta adresi giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="E-posta" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Şehir"
                                        name="city"
                                        rules={[{ required: true, message: 'Lütfen İl seçiniz' }]}
                                    >
                                        <Select
                                            placeholder="İl"
                                            className='payment-select'
                                            size="large"
                                            style={{ width: '100%' }}
                                            showSearch
                                            filterOption={(input, option) => {
                                                const normalizeText = (text: string) =>
                                                    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                                                return normalizeText(option?.children as unknown as string).includes(normalizeText(input));
                                            }}
                                            filterSort={(optionA, optionB) => {
                                                const normalizeText = (text: string) =>
                                                    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                                                return normalizeText(optionA?.children as unknown as string).localeCompare(normalizeText(optionB?.children as unknown as string));
                                            }}
                                        >
                                            {allCities.map((cityName) => (
                                                <Option key={cityName} value={cityName.toLowerCase()}>{cityName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Fatura Adresi"
                                name="billingAddress"
                                rules={[{ required: true, message: 'Lütfen Faturalandırma adresinizi giriniz' }]}
                            >
                                <Input placeholder="Faturalandırma adresi" className='payment-input' size="large" />
                            </Form.Item>
                        </div>
                    )}

                    {invoiceType === 'corporate' && (
                        <div className="corporate-invoice-form">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Ad"
                                        name="firstName"
                                        rules={[
                                            { required: true, message: 'Lütfen adınızı giriniz' },
                                            { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Adınız" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Soyad"
                                        name="lastName"
                                        rules={[
                                            { required: true, message: 'Lütfen soyadınızı giriniz' },
                                            { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Soyadınız" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Vergi Kimlik Numarası"
                                        name="taxNumber"
                                        rules={[
                                            { required: true, message: 'Lütfen Vergi Kimlik Numarası giriniz' },
                                            { pattern: /^\d{10,11}$/, message: 'Vergi Kimlik Numarası 10 veya 11 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Vergi Kimlik Numarası" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Vergi Dairesi"
                                        name="taxOffice"
                                        rules={[{ required: true, message: 'Lütfen Vergi Dairesi giriniz' }]}
                                    >
                                        <Input placeholder="Vergi Dairesi" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="E-posta"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Lütfen E-postanızı giriniz' },
                                            { type: 'email', message: 'Lütfen geçerli bir E-posta adresi giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="E-posta" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Telefon Numarası"
                                        name="phone"
                                        rules={[
                                            { required: true, message: 'Lütfen telefon numaranızı giriniz' },
                                            { pattern: /^\d{10}$/, message: 'Telefon numarası 10 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Telefon Numarası" maxLength={11} className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Firma Adı"
                                        name="companyName"
                                        rules={[{ required: true, message: 'Lütfen Firma Adı giriniz' }]}
                                    >
                                        <Input placeholder="Firma Adı" className='payment-input' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="İl"
                                        name="city"
                                        rules={[{ required: true, message: 'Lütfen İl giriniz' }]}
                                    >
                                        <Select
                                            placeholder="İl"
                                            className='payment-select'
                                            size="large"
                                            style={{ width: '100%' }}
                                            showSearch
                                            filterOption={filterCity}
                                        >
                                            {allCities.map((cityName) => (
                                                <Option key={cityName} value={cityName.toLowerCase()}>{cityName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Fatura Adresi"
                                name="billingAddress"
                                rules={[{ required: true, message: 'Lütfen Fatura Adresi giriniz' }]}
                            >
                                <Input placeholder="Fatura Adresi" className='payment-input' size="large" />
                            </Form.Item>
                        </div>
                    )}
                    <Form.Item>
                        <Row justify={"end"}>
                            <Button type="primary" htmlType="submit" size="large">
                                Ödeme Yap
                            </Button>
                        </Row>

                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PaymentModal;
