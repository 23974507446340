import { http } from "../http-common";

const getAll = (entityName: any) => {
        return http.get<any>(`/accounts/me/notes?entityName=${entityName}`);
};

const create = (data: any) => {
        return http.post<any>(`/accounts/me/notes`, data);
};


const remove = (id: number) => {
        return http.delete<any>(`/notes/${id}`);
};

const NoteService = {
    create,
    getAll,
    remove
}

export default NoteService;