// Profile.tsx
import { Button, Card, Col, Divider, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import UserService from '../services/UserService';
import { openNotification } from '../utils';
import ProfileImageSelector from '../components/Common/ProfileImageSelector';
import ParentList from '../components/Profile/ParentList';

const { Item } = Form;
const { Option } = Select;

const Profile = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState<any>();
  const [firstLetter, setFirstLetter] = useState('');
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    const { email, id, ...rest } = values;
    const postObj = {
      ...rest,
      school: values.school,
      grade: values.grade === 'Diğer' ? values.grade : Number(values.grade),
    };
    updateMe(postObj);
  };

  useEffect(() => {
    getMe();
  }, []);

  const updateMe = (postObj: any) => {
    setLoading(true);
    UserService.updateMe(postObj)
      .then(() => {
        openNotification('success', 'Başarılı', 'Profil bilgileri güncellendi');
        getMe();
      })
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const getMe = () => {
    setLoading(true);
    UserService.getMe()
      .then((response: any) => {
        const data = response.data || {};
        localStorage.setItem('firstName', data.firstName || '');
        localStorage.setItem('lastName', data.lastName || '');

        form.setFieldsValue({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          id: data.id,
          school: data.school,
          grade: data.grade,
          birthDate: data.birthDate,
        });
        setFirstLetter(data.firstName?.charAt(0) || '');
        setUser(data);
      })
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={10} xs={24}>
          <Card loading={loading} className='bg-cloud border-radius-md'>
            <Row gutter={24} align={'middle'}>
              <Col>
                <ProfileImageSelector />
              </Col>
              <Col>
                <p className='font-color-slate-blue m-0'>
                  <b>{user?.firstName} {user?.lastName}</b>
                </p>
                <p className='fs-14 font-gray m-0'>{user?.email}</p>
              </Col>
            </Row>
            <Row className='mt-24'>
              <Col span={24}>
                <p className='fs-16 fw-700 m-0'><b>Profil Detayları</b></p>
              </Col>
              <Col span={24}>
                <p className='fs-14 fw-400 font-gray'>
                  Burada sağladığınız bilgiler, kullanıcı hesabınızın yönetimi
                  ve destek süreçlerimizin iyileştirilmesi için kullanılacaktır.
                  Güvenliğiniz ve gizliliğiniz bizim için en önemli önceliktir.
                </p>
              </Col>
            </Row>
            <Divider className='bg-slate-blue-02' />
            <Form form={form} className='mb-64' onFinish={onFinish}>
              <Row gutter={24}>
                <Col xl={12} xs={24}>
                  <p className='fs-12 fw-700 mt-0 mb-4'>AD</p>
                  <Item
                    name="firstName"
                    rules={[
                      { required: true, message: 'Lütfen adınızı giriniz!' },
                      { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/, message: 'İsim özel karakter içermemelidir!' }
                    ]}
                  >
                    <Input size="large" placeholder='Ad' />
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <p className='fs-12 fw-700 mt-0 mb-4'>SOYAD</p>
                  <Item
                    name="lastName"
                    rules={[
                      { required: true, message: 'Lütfen soyadınızı giriniz!' },
                      { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/, message: 'Soyisim özel karakter içermemelidir!' }
                    ]}
                  >
                    <Input size="large" placeholder='Soyad' />
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xl={12} xs={24}>
                  <p className='fs-12 fw-700 mt-0 mb-4'>OKUL</p>
                  <Item name="school">
                    <Input size="large" placeholder='Okul' />
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <p className='fs-12 fw-700 mt-0 mb-4'>SINIF</p>
                  <Item
                    name="grade"
                    rules={[{ required: true, message: 'Lütfen sınıfınızı seçiniz!' }]}
                  >
                    <Select size="large" placeholder='Sınıf'>
                      {[...Array(9).keys()].map(i => (
                        <Option key={i + 4} value={(i + 4).toString()}>{i + 4}. Sınıf</Option>
                      ))}
                      <Option value="0">Diğer</Option>
                    </Select>
                  </Item>
                </Col>
              </Row>
              <Row>
                <Card loading={loading} className='bg-pure-white border-radius-md'>
                  <p className='fs-16 fw-700 m-0'><b>İletişim Bilgileri</b></p>
                  <p className='fs-14 fw-400 font-gray'>
                    Bu bölümdeki iletişim bilgileriniz, hesabınızla ilgili önemli güncellemeleri,
                    bildirimleri ve destek taleplerinizi yönetmek için kullanılacaktır.
                    Doğru ve güncel iletişim bilgileri sağlamanız, sizinle hızlı ve etkili bir şekilde
                    iletişim kurmamıza olanak tanır, böylece herhangi bir sorun veya ihtiyaç durumunda
                    size en iyi şekilde yardımcı olabiliriz.
                  </p>
                  <Row>
                    <Col xl={12} xs={24}>
                      <p className='fs-12 fw-700 mt-0 mb-4'>E-POSTA</p>
                      <Item
                        name="email"
                        rules={[{ required: true, message: 'Lütfen geçerli bir email girin!', type: 'email' }]}
                      >
                        <Input disabled size="large" placeholder='Email' />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} xs={24}>
                      <p className='fs-12 fw-700 mt-0 mb-4'>TELEFON</p>
                      <Item
                        name="phoneNumber"
                        rules={[
                          { required: true, message: 'Lütfen telefon numaranızı giriniz!' },
                          { pattern: /^[0-9]{10}$/, message: 'Telefon numarası 10 haneli olmalı ve sadece rakam içermelidir!' }
                        ]}
                      >
                        <Input size="large" placeholder='Telefon' />
                      </Item>
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row className='mt-24'>
                <Col>
                  <Button htmlType='submit' size="large" type="primary">
                    Güncelle
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24} md={24} xl={14}>
          <ParentList /> 
        </Col>
      </Row>
    </>
  );
};

export default Profile;
