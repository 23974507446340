import { Button, Col, Form, Input, Row, Typography, Layout } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';

const { Title, Text } = Typography;
const { Content } = Layout;

const EmailVerifySend = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    const handleSendVerificationEmail = () => {

        setLoading(true);
        if (isButtonDisabled) return;

        const userData = { email };

        const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/verification-send';

        axios.post(apiUrl, userData)
            .then((response) => {
                openNotification('success', 'Başarılı', 'Onay e-postası gönderildi. Lütfen E-Postanızı kontrol edin.');
                setIsButtonDisabled(true);
                setLoading(false);
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 60000); // 1 dakika sonra butonu aktif et
            })
            .catch((e) => {
                console.log(e);
                openNotification('error', 'Başarısız', 'Bir hata oluştu. Kontrol ederek tekrar deneyin.');
                setLoading(false);
            });
    };

    return (
        <Content>
            <Row justify={'center'}>
                <Col>
                    <Row align={'middle'} justify={'center'} className='text-center mb-4 '>
                        <Title level={4}>Aktivasyon E-postası gönder</Title>
                        <p>
                            E-posta adresinize bir onay e-postası göndereceğiz.
                        </p>
                    </Row>
                    <Row justify={'center'}>
                        <Button
                            onClick={handleSendVerificationEmail}
                            type='primary'
                            size="large" className="w-full"
                            disabled={isButtonDisabled}
                            loading={loading}
                        >
                            Onay E-Postası Gönder
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col>
                    <p>
                        Hesabınız Yok mu? <Link to={'/kaydol'}>Kaydol</Link>
                    </p>
                </Col>
            </Row>
        </Content>
    );
};

export default EmailVerifySend;