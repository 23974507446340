import { Card, Col, Row, Image, Avatar, Typography, Button } from 'antd'
import ProfileImg from '../assets/Dashboard/profile-sample-1.png'
import React, { useEffect, useState } from 'react'
import CountCard from '../components/Dashboards/CountCard'
import StudentQuestionService from '../services/StudentQuestionService'
import LessonService from '../services/LessonService'
import { Link, NavLink } from 'react-router-dom'
import ActivityCard from '../components/Dashboards/ActivityCard'
import TimelineActivity from '../components/Dashboards/TimelineActivity'
import LiveLesson from '../components/Dashboards/LiveLesson'
import VideoHistory from '../components/Dashboards/VideoHistory'
import QuestionCard from '../components/Dashboards/QuestionCard'
import Homework from '../components/Dashboards/Homework'
import AssignmentService from '../services/AssignmentService'
import VideoHistoryService from '../services/VideoHistoryService'
import ActiveCourse from '../components/Dashboards/ActiveCourse'
import ProfileAvatar from '../assets/avatar/avatar.svg'
import Shortcut from '../components/Dashboards/Shortcut'
import WeeklyCalendar from '../components/Dashboards/WeeklyCalendar'
import ProfileLink from '../components/Common/ProfileLink'
import ConsecutiveDaysCard from '../components/Dashboards/ConsecutiveDaysCard'
import LiveLessonService from '../services/LiveLessonService'
import AIAsistantCard from '../components/Dashboards/AIAsistantCard'

const Dashboard = () => {
  const { Title } = Typography;

  const [firstName, setFirstName] = useState<string | null>()
  const [lastName, setLastName] = useState<string | null>()
  const [liveLessonCount, setLiveLessonCount] = useState<any>(0)
  const [videoWatchedCount, setVideoWatchedCount] = useState<any>(0)
  const [questionCount, setQuestionCount] = useState<any>(0)

  const [questionStatistics, setQuestionStatistics] = useState<any>({ answeredQuestionCount: 0, questionCount: 0 })
  const [assignmentStatistics, setAssignmentStatistics] = useState<any>({ assignmentIsDoneCount: 0, assignmentCount: 0 })

  useEffect(() => {
    let firstName: string | null = localStorage.getItem('firstName')
    let lastName: string | null = localStorage.getItem('lastName')
    let id: string | null = localStorage.getItem('id')

    setFirstName(firstName)
    setLastName(lastName)

    const getLiveLessons = async () => {
      try {
        const [liveLessonsResponse, lessonsResponse, questionsResponse] = await Promise.all([
          LiveLessonService.getAll(`?isApprovel=true&isComplete=false`), // 
          LessonService.getAll(),
          StudentQuestionService.getAllMe()
        ]);

        const data: any = liveLessonsResponse?.data;
        const lessonData: any = lessonsResponse?.data;
        const questionsData: any = questionsResponse?.data.slice(-3); // Son üç soruyu al

        setLiveLessonCount(data.length ?? 0);

        const liveLessonsWithDetails = data.map((liveLesson: any) => {
          const matchingLesson = lessonData.find(
            (lesson: any) => lesson.id === liveLesson.lessonId
          );

          return {
            ...liveLesson,
            lessonName: matchingLesson?.name
          };
        });


        const questionsWithLessonNames = questionsData.map((question: any) => {
          const matchingLesson = lessonData.find(
            (lesson: any) => lesson.id === question.lessonId
          );

          return {
            ...question,
            lessonName: matchingLesson?.name
          };
        });
        setQuestionCount(questionsResponse?.data.length ?? 0);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    getLiveLessons();
    getAllMeStatistics();
    getAllMeStatisticsAssignment();
    getAllMeVideoStatistics();
  }, [])

  const getAllMeStatisticsAssignment = () => {
    AssignmentService.getAllMeStatistic()
      .then((response: any) => {
        const data = response.data
        setAssignmentStatistics(data)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAllMeStatistics = () => {
    StudentQuestionService.getAllMeStatistics()
      .then((response: any) => {
        const data = response.data
        setQuestionStatistics(data)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAllMeVideoStatistics = () => {
    VideoHistoryService.getAllMeStatistics()
      .then((response: any) => {
        const data = response.data
        setVideoWatchedCount(data.watchedCount)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} lg={16}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={16}>
              <ActiveCourse />
            </Col>
            <Col xs={24} md={24} lg={24} xl={8}>
              <TimelineActivity></TimelineActivity>
            </Col>
            <Col xs={24} md={24} lg={24} xl={16}>
              <VideoHistory />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Card className='pt-0'>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ConsecutiveDaysCard></ConsecutiveDaysCard>
              </Col>
              {/* <Col span={24}>
                <AIAsistantCard></AIAsistantCard>
              </Col> */}
              <Col span={24}>
                <WeeklyCalendar></WeeklyCalendar>
              </Col>
              <Col span={24}>
                <Shortcut></Shortcut>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard