import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, notification, Row, Col, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { INoteListResponse, INoteResponse } from './Type/Note';
import CreateNote from './Components/CreateNote';
import NoteDrawer from './Components/NoteDrawer';
import { formatDate } from '../../utils';
import NoteService from './Service/NoteService';

interface NotesProps {
  entityName: string;
  entityId: any;
}

const Notes: React.FC<NotesProps> = ({ entityId, entityName }) => {
  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState<INoteListResponse[]>();
  const [createVisible, setCreateVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState<INoteResponse>();
  const { Title } = Typography;

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = () => {
    NoteService.getAll(entityName, entityId)
      .then((response: any) => {
        let data: INoteListResponse[] = response.data;
        setNotes(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleDelete = (id: number) => {
    NoteService.remove(id)
      .then(() => {
        getNotes();
        notification.success({
          message: 'Başarılı',
          description: 'Not başarıyla silindi.',
        }); // Silme işleminden sonra listeyi yenilemek için
      })
      .catch((e: Error) => {
        notification.error({
          message: 'Hata',
          description: 'Silme sırasında bir hata oluştu.',
        });
        console.error('Silme sırasında hata:', e);
      });
  };

  const columns: ColumnsType<INoteListResponse> = [
    {
      title: 'Not',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt: string) => formatDate(createdAt), // Oluşturulma tarihini formatlama
    },
    // {
    //   title: 'Değiştirilme Tarihi',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (updatedAt: string) => formatDate(updatedAt), // Değiştirilme tarihini formatlama
    // },
    {
      title: '',
      key: 'actions',
      width: 200,
      render: (_, record: any) => (
        <>
          {/* <Button type="link" onClick={() => handleDetail(record)}>
            Detay
          </Button> */}
          <Popconfirm
            title="Bu notu silmek istediğinize emin misiniz?"
            onConfirm={() => handleDelete(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger type="link">
              Sil
            </Button>
          </Popconfirm>
        </>

      ),
    }
  ];

  const handleDetail = (note: INoteResponse) => {
    setSelectedNote(note);
    setVisible(true);
  };

  const handleCreate = () => {
    setCreateVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setCreateVisible(false);
  };

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col>
        <Title level={4} className='mt-16'>Notlar</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={handleCreate}>
            Not Ekle
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={notes} rowKey="id" />
      <NoteDrawer refreshTrigger={getNotes} selectedNote={selectedNote} onClose={onClose} visible={visible} />
      <CreateNote entityId={entityId} entityName={entityName} onClose={onClose} createVisible={createVisible} refreshTrigger={getNotes} />
    </>
  );
};

export default Notes;
