import { Avatar, Button, Col, Popconfirm, Row, Tooltip, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Layout, { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from './assets/saha-logo.svg';

import { ReactComponent as HomeIcon } from './assets/menu/dashboard.svg'
import { ReactComponent as PackageIcon } from './assets/menu/course.svg'
import { ReactComponent as Calendar } from './assets/menu/calendar.svg'
import { ReactComponent as SupportIcon } from './assets/menu/support.svg'
import { ReactComponent as LogoutIcon } from './assets/menu/logout.svg'
import ProfileLink from './components/Common/ProfileLink';
import PageHeader from './components/Common/PageHeader';

const MainLayout = () => {
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState<string | null>()

    const logout = () => {
        localStorage.clear();
        navigate('/giris');
    };
    const { Title } = Typography;

    useEffect(() => {
        let firstName: string | null = localStorage.getItem('firstName')

        setFirstName(firstName)
    }, [])

    return (
        <Layout className='app-layout'>
            <Sider className='sidebar bg-gradient'>
                <Link to="/" className="menu-item mt-16"><img src={Logo} width={"60px"} alt='logo' /></Link>
                <div className="menu">
                    <Tooltip title="Genel Görünüm">
                        <NavLink to="/" className="menu-item">
                            <HomeIcon />
                        </NavLink>
                    </Tooltip>
                    <Tooltip title="Kurslar">
                        <NavLink to="/my-course" className="menu-item">
                            <PackageIcon />
                        </NavLink>
                    </Tooltip>
                    <Tooltip title="Takvim">
                        <NavLink to="/reminder" className="menu-item">
                            <Calendar />
                        </NavLink>
                    </Tooltip>
                    <Tooltip title="Destek">
                        <NavLink to="/support" className="menu-item">
                            <SupportIcon />
                        </NavLink>
                    </Tooltip>
                </div>
                <Tooltip title="Çıkış">
                    <Popconfirm
                        title="Çıkmak istediğinize emin misiniz?"
                        okText="Evet"
                        cancelText="Hayır"
                        onConfirm={logout}
                        icon
                    >
                        <Button type='link' className="menu-item">
                            <LogoutIcon />
                        </Button>
                    </Popconfirm>
                </Tooltip>
            </Sider>
            <Layout>
                <Content className='main-content'>
                    <PageHeader></PageHeader>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

export default MainLayout