import React, { useEffect, useState } from 'react';
import { Drawer, Table, Tag, Tooltip, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import NoteService from '../../services/NoteService';
import { formatDate } from '../../utils';
import UserService from '../../services/UserService';
const { Title } = Typography;

const LiveLessonCreditHistory: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [notes, setNotes] = useState<any[]>([]);
    const [liveLessonCredit, setLiveLessonCredit] = useState(0);

    useEffect(() => {
        getMe();
    }, []);

    useEffect(() => {
        if (visible) { getNotes(); getMe(); }
    }, [visible]);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const getMe = () => {
        UserService.getMe()
            .then((response: any) => {
                localStorage.setItem('liveLessonCredit', response.data.liveLessonCredit);
                setLiveLessonCredit(response.data.liveLessonCredit);
            })
            .catch((e: Error) => {
                console.error(e);
            });
    };

    const getNotes = () => {
        setLoading(true);
        NoteService.getAll("LiveLessonCredit")
            .then((response: any) => {
                let data = response.data.map((note: any) => ({
                    ...note,
                    properties: JSON.parse(note.properties)
                }));
                data.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setNotes(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const columns = [
        {
            title: 'Tarih',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => formatDate(text),
        },
        {
            title: 'İşlem',
            dataIndex: ['properties', 'type'],
            key: 'type',
            render: (type: string) => (
                <Tag color={type === 'DECREASED' ? 'error' : 'success'}>
                    {type === 'DECREASED' ? 'KULLANILDI' : 'YÜKLENDİ'}
                </Tag>
            ),
        },
        {
            title: 'Kredi',
            dataIndex: ['properties', 'credit'],
            key: 'credit',
        },
        {
            title: 'Toplam Kredi',
            dataIndex: ['properties', 'totalCredit'],
            key: 'totalCredit',
        },
    ];

    return (
        <div>
            <Title level={4} style={{ display: "inline", margin: 0, marginRight: 8 }}>
                Canlı Ders Hakkı: {liveLessonCredit}
            </Title>
            <Tooltip title="Kredi Geçmişini Görüntüle">
                <HistoryOutlined style={{ fontSize: '24px', cursor: 'pointer' }} onClick={showDrawer} />
            </Tooltip>
            <Drawer
                title="Canlı Ders Kredi Geçmişi"
                placement="right"
                onClose={onClose}
                visible={visible}
                width={720}
            >
                <Table
                    dataSource={notes}
                    columns={columns}
                    rowKey="id"
                    loading={loading}
                />
            </Drawer>
        </div>
    );
};

export default LiveLessonCreditHistory;