import { Card, Col, Divider, Radio, Row, Space, Image, Statistic, Tabs } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import React from 'react'

interface QuizResultProps {
  quizResult: any;
  questions: any[];
  onQuestionClick: (index: number) => void;
}
const QuizResult: React.FC<QuizResultProps> = ({ quizResult, questions, onQuestionClick }) => {
  console.log(questions);
  const totalQuestions = quizResult.answeredQuestions.length;
  const correctAnswers = quizResult.answeredQuestions.filter((q: any) => q?.isCorrect).length;
  const incorrectAnswers = quizResult.answeredQuestions.filter((q: any) => !q?.isCorrect && q?.option !== null).length;
  const emptyAnswers = quizResult.answeredQuestions.filter((q: any) => q?.option === null).length;

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} dakika ${remainingSeconds} saniye`;
  };

  const resultDurationFormatted = formatDuration(quizResult.resultDuration);

  const renderOptions = (question: any, answer: any) => {
    const options = ['A', 'B', 'C', 'D', 'E'].slice(0, question?.answerType);
    return (
      <Radio.Group value={answer?.option}>
        <Space direction="vertical">
          {options.map(option => (
            <Radio key={option} value={option} disabled>
              {option}) {option === answer.correctedAnswer ? <b>(Doğru Cevap)</b> : null}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    );
  };

  const renderImage = (imageData: any) => {
    const pdfBlob = new Blob([imageData], { type: 'application/jpg' });
    let fileData = URL.createObjectURL(pdfBlob);
    return <Image style={{ maxHeight: '500px', width: "auto" }} src={fileData} fallback="error.png" />
  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'İstatistik',
            key: '1',
            children: <>
              <Row gutter={[48, 48]}>
                <Col span={5}>
                  <Statistic title="Doğru Cevap" value={correctAnswers} />
                </Col>
                <Col span={5}>
                  <Statistic title="Yanlış Cevap" value={incorrectAnswers} />
                </Col>
                <Col span={5}>
                  <Statistic title="Toplam Soru" value={totalQuestions} />
                </Col>
                <Col span={5}>
                  <Statistic title="Boş" value={emptyAnswers} />
                </Col>
                <Col span={4}>
                  <Statistic title="Sınav Süresi" value={resultDurationFormatted} />
                </Col>
              </Row>
            </>
            ,
          },
          {
            label: 'Tüm Sorular',
            key: '2',
            children: <Row gutter={[24, 24]}>
              {quizResult.answeredQuestions.map((answer: any, index: number) => {
                const row = questions.find(q => q.question.id === answer.questionId);
                if (!row) return null;
                return (
                  <Col span={12}>
                    <Card title={
                      <Row justify={"space-between"}>
                        <Col>
                          Soru: {index + 1}
                        </Col>
                        <Col>
                          Konu: {row.question.subject}
                        </Col>
                        <Col>
                          Ders: {row.lesson.name}
                        </Col>
                      </Row>
                    } key={row.question.id} className='mb-16'>
                      <Row justify="center">
                        <Col>
                          {row.question.file !== null && (
                            renderImage(row.question.file)
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-24">
                        <Col>{renderOptions(row.question, answer)}</Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>,
          },
          {
            label: 'Yanlış Sorular',
            key: '3',
            children: <Row gutter={[24, 24]}>
              {quizResult.answeredQuestions.map((answer: any, index: number) => {
                const row = questions.find(q => q.question.id === answer.questionId);
                if (!row || answer.isCorrect) return null;
                return (
                  <Col span={12}>
                    <Card title={
                      <Row justify={"space-between"}>
                        <Col>
                          Soru: {index + 1}
                        </Col>
                        <Col>
                          Konu: {row.question.subject}
                        </Col>
                        <Col>
                          Ders: {row.lesson.name}
                        </Col>
                      </Row>
                    } key={row.question.id} className='mb-16'>
                      <Row justify="center">
                        <Col>
                          {row.question.file !== null && (
                            renderImage(row.question.file)
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-24">
                        <Col>{renderOptions(row.question, answer)}</Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>,
          },
        ]}
      />
      <Divider />

    </>
  )
}

export default QuizResult