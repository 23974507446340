import React, { useEffect, useState, useRef } from 'react';
import { IPackageResponse } from '../types/Package';
import { Card, Col, Input, Row, Tag, Typography, Empty, Skeleton } from 'antd';
import CourseCard from '../components/Course/CourseCard';
import { NavLink } from 'react-router-dom';
import ProfileLink from '../components/Common/ProfileLink';
import PaymentService from '../services/PaymentService';
import CourseService from '../services/CourseService';

const { Title } = Typography;
const { Search } = Input;

const CourseList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [buyer, setBuyer] = useState<any>({});
  const [courseList, setCourseList] = useState<IPackageResponse[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<IPackageResponse[]>([]);
  const [filterTag, setFilterTag] = useState<string>('Tümü');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checkoutFormContent, setCheckoutFormContent] = useState<string | null>(null);
  const [isDivEmpty, setIsDivEmpty] = useState(false);
  const divRef = useRef<any>(null);

  const tags = ['Tümü', '4. 5. 6. 7. 8. Sınıf', '9. 10. ve 11. Sınıf', 'YKS', 'AYT', 'TYT', 'LGS', 'KPSS'];

  useEffect(() => {
    getCourseList();
  }, []);

  useEffect(() => {
    filterCourses();
  }, [filterTag, searchTerm, courseList]);

  useEffect(() => {
    if (buyer.name || buyer.companyName) {
      handlePaymentSubmit();
    }
  }, [buyer]);

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement && checkoutFormContent) {
      if (divElement.children.length === 0) {
        setIsDivEmpty(true);
      }
    }
  }, [checkoutFormContent]);

  useEffect(() => {
    if (checkoutFormContent) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = checkoutFormContent;

      const scriptElements = wrapper.getElementsByTagName('script');
      for (let i = 0; i < scriptElements.length; i++) {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.innerHTML = scriptElements[i].innerHTML;
        document.body.appendChild(scriptElement);
      }
    }
  }, [checkoutFormContent]);

  const getCourseList = () => {
    setLoading(true);
    CourseService.getSaleList()
      .then((response: any) => {
        const data: IPackageResponse[] = response.data;
        setCourseList(data.sort((a, b) => a.name.localeCompare(b.name))); // Sort courses A-Z by name
        setFilteredCourses(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const normalizeString = (str: string) => {
    return str
      .normalize('NFD') // Decomposes combined letters into base and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // Removes diacritical marks
      .toLowerCase();
  };

  const filterCourses = () => {
    let filtered = courseList;

    if (searchTerm.length >= 3) {
      const normalizedSearchTerm = normalizeString(searchTerm);
      filtered = filtered.filter((course: any) => {
        const normalizedCourseName = normalizeString(course.name);
        const tags = course.tags.toLowerCase().split(',');
        return (
          normalizedCourseName.includes(normalizedSearchTerm) || // Check in course names
          tags.some((tag: string) => normalizeString(tag).includes(normalizedSearchTerm)) // Check in tags
        );
      });
    }

    if (filterTag !== 'Tümü') {
      if (filterTag === '9. 10. ve 11. Sınıf') {
        filtered = filtered.filter((course: any) => {
          const tags = course.tags.toLowerCase().split(',');
          return (
            tags.includes('9.sınıf') ||
            tags.includes('10.sınıf') ||
            tags.includes('11.sınıf')
          );
        });
      } else if (filterTag === '4. 5. 6. 7. 8. Sınıf') {
        filtered = filtered.filter((course: any) => {
          const tags = course.tags.toLowerCase().split(',');
          return (
            tags.includes('4.sınıf') ||
            tags.includes('5.sınıf') ||
            tags.includes('6.sınıf') ||
            tags.includes('7.sınıf') ||
            tags.includes('8.sınıf')
          );
        });
      } else {
        filtered = filtered.filter((course: any) => {
          const tags = course.tags.toLowerCase().split(',');
          return tags.includes(normalizeString(filterTag.toLowerCase()));
        });
      }
    }

    setFilteredCourses(filtered);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTagChange = (tag: string) => {
    setFilterTag(tag);
  };

  const handlePaymentSubmit = () => {
    PaymentService.purchase(buyer)
      .then((response: any) => {
        setCheckoutFormContent(response.data.htmlContent);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      {isDivEmpty ? '' : <>
        <Row gutter={[16, 16]} justify={"space-between"} align={"middle"} className='mb-16'>
          <Col>
            <div className="tag-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {tags.map(tag => (
                <Tag
                  key={tag}
                  color={filterTag === tag ? '#935ac0' : 'default'}
                  onClick={() => handleTagChange(tag)}
                  style={{
                    cursor: 'pointer',
                    padding: '8px 16px', // Adjust padding for size
                    fontSize: '14px',    // Increase font size
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </Col>
          <Col>
            <Search
              size="large"
              placeholder="Ara..."
              onChange={handleSearchInputChange}
              value={searchTerm}
              enterButton
              allowClear
            />
          </Col>
        </Row>

        {(!loading) ? <Row gutter={[24, 24]}>
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course: IPackageResponse) => (
              <Col xl={6} lg={8} md={12} xs={24} key={course.id}>
                <CourseCard course={course} setBuyer={setBuyer} />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Row style={{ height: 'calc(100vh - 200px)' }} justify={"center"} align={"middle"}>
                <Empty description="Arama sonucunda kurs bulunamadı" />
              </Row>
            </Col>
          )}
        </Row> : <Skeleton />}
      </>}
      <div id="iyzipay-checkout-form" className="responsive" ref={divRef}></div>
      {checkoutFormContent && (
        <div dangerouslySetInnerHTML={{ __html: checkoutFormContent }} />
      )}
    </>
  );
};

export default CourseList;
