import { Card, Drawer, Divider, Image, Upload, Button, Form, Input, UploadProps, UploadFile } from 'antd';
import React, { useEffect, useState } from 'react';
import { openNotification } from '../../utils';
import AssignmentService from './../../services/AssignmentService';
import './assignment-detail.css';
import {
    PlusOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

const AssignmentDetail: React.FC<any> = ({ item, getLiveAssignments, getTrigger }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filesUrl, setFilesUrl] = useState<any>({});
    const [filesUrl2, setFilesUrl2] = useState<any>({});
    const [selectedAssignment, setSelectedAssignment] = useState(0);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const fetchFile = async () => {
            if (item?.image) {
                try {
                    const response = await AssignmentService.getFile(item?.image);
                    const blobType = response.headers['content-type'];
                    const fileBlob = new Blob([response.data], { type: blobType });
                    setFilesUrl(URL.createObjectURL(fileBlob));
                } catch (error) {
                    // Hata mesajını konsola yazdırın.
                    console.error('File fetching failed:', error);
                }
            }
            if (item?.responseImage) {
                try {
                    const response = await AssignmentService.getFile(item?.responseImage);
                    const blobType = response.headers['content-type'];
                    const fileBlob = new Blob([response.data], { type: blobType });
                    setFilesUrl2(URL.createObjectURL(fileBlob));
                } catch (error) {
                    // Hata mesajını konsola yazdırın.
                    console.error('File fetching failed:', error);
                }
            }
        }
        if (isModalOpen) {
            fetchFile();
        }

    }, [isModalOpen])

    const handleRespondSubmit = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                let postObj: any = {
                    responseContent: values.responseContent,
                    isDone: true,
                };

                AssignmentService.update(item?.id, postObj, fileList[0])
                    .then((response: any) => {
                        form.resetFields();
                        openNotification('success', 'Başarılı', 'Ödeve Cevap Verildi.');
                        setIsModalOpen(false)
                        getTrigger();
                    }).catch((e: Error) => {
                        console.log(e)
                    });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };


    const props: UploadProps = {
        onPreview: async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as any);
            }

        },
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
                // If there's already a file in the list, reject the new file
                return Upload.LIST_IGNORE;
            }
            // If not, add the new file to the list
            setFileList([file]);
            return false;
        },
        fileList,
        defaultFileList: fileList, // Show the initial file list
        accept: '.jpg', // Accept only jpg files
        multiple: false, // Disable multiple file selection
    };

    const getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Yükle</div>
        </button>
    );

    return (
        <>
            <Button
                className='bg-saha-purple btn fw-700 font-color-white'
                onClick={showModal}
            >
                {/* {item.studentName ?? ''} */}
                Detay
            </Button>

            {/* <Button className='mr-15 assignment-detail' key={item?.id} onClick={showModal} type={item.status == 'DONE' ? 'default' : 'primary'} size="small">{item?.title}</Button> */}
            <Drawer title="Ödev Detay" placement="right" onClose={handleCancel} open={isModalOpen} style={{ padding: '0px', margin: '0px', border: 'none' }}>
                <Card loading={loading} className='assignment-detail-card' style={{ border: 'none' }} bodyStyle={{ padding: '0px', margin: '0px', border: 'none' }}>
                    <h4>Ders</h4>
                    <p>{item?.lessonName}</p>
                    <Divider />
                    <h4>Ödev İçerik</h4>
                    <p>{item?.content}</p>
                    {item?.image ?
                        <>
                            <Divider />
                            <h4>Soru Görsel</h4>
                            {filesUrl && (
                                <Image src={filesUrl} fallback="error.png" />
                            )}
                        </> : null
                    }
                    {item?.responseContent ?
                        <>
                            <Divider />
                            <h4>Cevap Detay</h4>
                            <p>{item.responseContent}</p>
                        </> :
                        <>
                            <Divider></Divider>
                            <Form form={form} layout="vertical">
                                <Form.Item name="responseContent" label="Cevap" rules={[{ required: true, message: 'Cevap giriniz' }]}>
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="responseImage" label="Görsel Cevap" >
                                    <Upload {...props} listType="picture-card"
                                    >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>
                            </Form>
                            <Button onClick={() => handleRespondSubmit()} disabled={item.isDone} style={{ marginLeft: 10 }}>Cevapla</Button>
                        </>
                    }
                    {item?.responseImage ?
                        <>
                            <Divider />
                            <h4>Cevap Görsel</h4>
                            {filesUrl2 && (
                                <Image src={filesUrl2} fallback="error.png" />
                            )}
                        </> : null
                    }
                    {/* <Divider />
                    <h4>Cevap Döküman</h4>
                    <p>{item.responseContent}</p> */}
                    {/* <h4>Ders Tarihi</h4> */}

                    {/* <Divider /> */}
                    <Divider />
                </Card>
            </Drawer >
        </>
    );
}
export default AssignmentDetail;
