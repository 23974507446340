// components/Parents/ParentList.tsx
import { Button, Card, Col, Drawer, Form, Input, Row, Table, Space, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import { openNotification } from '../../utils';

const { Item } = Form;

const ParentList = () => {
  const [parentForm] = Form.useForm();
  const [parents, setParents] = useState<any[]>([]);
  const [parentLoading, setParentLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentParent, setCurrentParent] = useState<any>(null); // To store the currently edited parent

  useEffect(() => {
    getParent();
  }, []);

  useEffect(() => {
    if (!open) parentForm.resetFields();
  }, [open]);

  const getParent = () => {
    setParentLoading(true);
    UserService.getParent()
      .then((response: any) => {
        setParents(response.data || []);
      })
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => setParentLoading(false));
  };

  const showDrawer = (parent = null) => {
    setCurrentParent(parent);
    if (parent) {
      parentForm.setFieldsValue(parent);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentParent(null);
  };

  const saveParent = (values: any) => {
    const postObj = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
    };

    const request = currentParent
      ? UserService.updateParent(currentParent.id, postObj)
      : UserService.createParent(postObj);

    request
      .then(() => {
        openNotification('success', 'Başarılı', currentParent ? 'Veli başarıyla güncellendi.' : 'Veli başarıyla eklendi.');
        getParent();
        onClose();
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };

  // Function to handle parent deletion
  const handleDeleteParent = (parentId: string) => {
    UserService.removeParent(parentId)
      .then(() => {
        openNotification('success', 'Başarılı', 'Veli başarıyla silindi.');
        getParent(); // Refresh the list after deletion
      })
      .catch((error: Error) => {
        console.error(error);
        openNotification('error', 'Hata', 'Veli silinirken bir hata oluştu.');
      });
  };

  // Define the columns for the table
  const columns = [
    {
      title: 'Ad Soyad',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'İşlem',
      key: 'actions',
      render: (_: any, record: any) => (
        <Space>
          <Button onClick={() => showDrawer(record)} type="default">
            Düzenle
          </Button>
          <Popconfirm
            title="Veliyi silmek istediğinize emin misiniz?"
            okText="Evet"
            cancelText="Hayır"
            onConfirm={() => handleDeleteParent(record.id)}
          >
            <Button danger type="primary">
              Sil
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card className='bg-cloud' loading={parentLoading}>
        <Row className='mb-24' justify={"space-between"}>
          <Col>
            <h2 className='mt-0'>Veli Bilgileri</h2>
          </Col>
          <Col>
            <Button onClick={() => showDrawer()} type="primary">Veli Ekle</Button>
          </Col>
        </Row>
        <Table
          dataSource={parents}
          columns={columns}
          rowKey="id"
          loading={parentLoading}
          pagination={{ pageSize: 10 }}
        />
      </Card>

      <Drawer title={currentParent ? "Veli Düzenle" : "Veli Ekle"} placement="right" onClose={onClose} open={open}>
        <Form form={parentForm} onFinish={saveParent} layout="vertical">
          <Item
            name="fullName"
            label="Ad Soyad"
            rules={[{ required: true, message: 'Ad soyad zorunlu' }]}
          >
            <Input  size="large" />
          </Item>
          <Item
            name="email"
            label="E-Posta"
            rules={[
              { type: 'email', message: 'Geçerli bir e-posta adresi giriniz' },
              { required: true, message: 'E-Posta zorunlu' }
            ]}
          >
            <Input  size="large" />
          </Item>
          <Item
            name="phone"
            label="Telefon"
            rules={[
              { required: true, message: 'Telefon numarası zorunludur' },
              { pattern: /^[0-9]{10}$/, message: 'Telefon numarası 10 haneli olmalı ve sadece rakam içermelidir!' }
            ]}
          >
            <Input type="text"  size="large" placeholder='5XXXXXXXXX' />
          </Item>
          <Item>
            <Row justify={'end'} gutter={[16, 16]}>
              <Col>
                <Button size="large" onClick={onClose}>
                  Vazgeç
                </Button>
              </Col>
              <Col>
                <Button   size="large" type="primary" htmlType="submit">
                  {currentParent ? "Güncelle" : "Kaydet"}
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ParentList;
