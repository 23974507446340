import {http} from "../http-common";
import { IVideoResponse } from "../types/Video";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('video.list')) {
        return http.get<IVideoResponse>(`/video`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('video.show')) {
        return http.get<IVideoResponse>(`/video/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const VideoService = {
    getAll,
    get,
}

export default VideoService;