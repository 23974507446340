import {http} from "../http-common";

const getAll = () => {
        return http.get<any>(`/students/me/history`);
};

const getConsecutiveLoginDays = () => {
    return http.get<any>(`/students/me/consecutive-days`);
};

const AccountHistoryService = {
    getAll,
    getConsecutiveLoginDays
}

export default AccountHistoryService;