import { Button, Col, Form, Input, Row } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { openNotification } from '../../utils';
import axios from 'axios';
import UserService from '../../services/UserService';

const Register = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract course ID from the URL
  const searchParams = new URLSearchParams(location.search);
  const affiliate: any = searchParams.get('affiliate');
  const courseId = searchParams.get('course'); // Get the course ID from the URL
  localStorage.setItem('affiliate', affiliate);

  const onFinish = (values: any) => {
    setLoading(true);

    let userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      phone: values.phone,
      courseId: courseId ? parseInt(courseId) : undefined, // Include course ID if it exists
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/register';

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData,
    };

    axios
      .request(config)
      .then((response: any) => {
        console.log(response);
        openNotification('success', 'Başarılı', 'Başarıyla Kayıt Oluşturuldu');
        // After registration, log in the user automatically
        loginUser(values.email, values.password);
      })
      .catch((e: any) => {
        console.log(e?.response.data.errorCode);
        if (e?.response.data.errorCode === 'account-already-exists') {
          openNotification('error', 'Başarısız', 'E-posta adresi ile daha öncesinde kayıt oluşturulmuş. Farklı bir e-posta adresi deneyiniz.');
        } else {
          openNotification('error', 'Başarısız', 'Kayıt Yapılamadı.');
        }
        setLoading(false);
      });
  };

  const loginUser = (email: string, password: string) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/login';

    let loginData = {
      email: email,
      password: password,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: loginData,
    };

    axios
      .request(config)
      .then((response: any) => {
        console.log(response);
        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);

        // Fetch user details and update
        userGet();
        updateMe();
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification('error', 'Başarısız', 'Giriş Yapılamadı.');
        setLoading(false);
      });
  };

  const userGet = () => {
    UserService.getMe()
      .then((response: any) => {
        console.log(response);
        const role = response.data?.roles[0]?.name;

        localStorage.setItem('firstName', response.data.firstName);
        localStorage.setItem('lastName', response.data.lastName);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('phoneNumber', response.data.phoneNumber);
        localStorage.setItem('profilePicture', response.data.profilePicture);
        localStorage.setItem('role', response.data?.roles[0]?.name);
        localStorage.setItem('id', response.data?.id);
        localStorage.setItem('permissions', JSON.stringify(response.data?.roles[0]?.permissions));

        if (courseId) {
          navigate(`/course/${courseId}`);
        } else {
          navigate('/');
        }
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Giriş yapılamadı, Saha Eğitim öğrencisi değilsiniz.');
        setLoading(false);
        localStorage.clear();
        console.log(e);
      });
  };

  const updateMe = () => {
    const affiliate = localStorage.getItem('affiliate');
    console.log('affiliate', affiliate);
    if (affiliate != null) {
      const postObj = {
        affiliate: affiliate,
      };
      UserService.updateMe(postObj)
        .then((response: any) => {
          localStorage.removeItem('affiliate');
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordValidator = (_: any, value: any) => {
    const errors: string[] = [];

    if (!value) {
      return Promise.reject(new Error('Şifre boş bırakılamaz!'));
    }
    if (value.length < 8) {
      errors.push('Şifre en az 8 karakter olmalıdır!');
    }
    if (!value.match(/[A-Z]/)) {
      errors.push('En az bir büyük harf içermelidir!');
    }
    if (!value.match(/[a-z]/)) {
      errors.push('En az bir küçük harf içermelidir!');
    }
    if (!value.match(/[0-9]/)) {
      errors.push('En az bir sayı içermelidir!');
    }
    if (errors.length > 0) {
      return Promise.reject(new Error(errors.join('\n')));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        name="register_form"
        className="register-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2 className="fs-36 fw-400 h-3 font-color-header">Kayıt Ol</h2>
        <p className="fs-20 fw-400 font-color-subtext">Hoşgeldiniz. Lütfen bilgilerinizi doldurun.</p>
        <Row gutter={8}>
          <Col span={12}>
            <p className="fs-16 fw-400 font-color-header mt-0 mb-4">İsim</p>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Lütfen İsminizi giriniz!' }]}
            >
              <Input size="large" className="form-input" placeholder="İsim" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <p className="fs-16 fw-400 font-color-header mt-0 mb-4">Soyisim</p>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Lütfen Soyisminizi giriniz!' }]}
            >
              <Input size="large" className="form-input" placeholder="Soyisim" />
            </Form.Item>
          </Col>
        </Row>
        <p className="fs-16 fw-400 font-color-header mt-0 mb-4">E-Posta</p>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Lütfen E-posta giriniz!' }]}
        >
          <Input className="form-input" size="large" placeholder="E-Posta" />
        </Form.Item>
        <p className="fs-16 fw-400 font-color-header mt-0 mb-4">Telefon Numarası</p>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: 'Lütfen telefon numaranızı giriniz!' },
            { len: 10, message: 'Telefon numarası 10 haneli olmalıdır!' },
            { pattern: /^\d+$/, message: 'Sadece rakam girilebilir!' },
          ]}
        >
          <Input className="form-input" size="large" placeholder="5XXXXXXXXX" />
        </Form.Item>
        <p className="fs-16 fw-400 font-color-header mt-0 mb-4">Şifre</p>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Lütfen şifrenizi girin!' },
            {
              validator: (_: any, value: any) => {
                const errors: string[] = [];

                if (!value) {
                  return Promise.reject(new Error('Şifre boş bırakılamaz!'));
                }
                if (value.length < 8) {
                  errors.push('Şifre en az 8 karakter olmalıdır!');
                }
                if (!value.match(/[A-Z]/)) {
                  errors.push('En az bir büyük harf içermelidir!');
                }
                if (!value.match(/[a-z]/)) {
                  errors.push('En az bir küçük harf içermelidir!');
                }
                if (!value.match(/[0-9]/)) {
                  errors.push('En az bir sayı içermelidir!');
                }

                if (errors.length > 0) {
                  return Promise.reject(new Error(errors.join('\n')));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input.Password
            size="large"
            style={{ padding: '0.7em' }}
            placeholder="Şifre"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <p className="fs-16 fw-400 font-color-header mt-0 mb-4">Şifreyi Onayla</p>
        <Form.Item
          name="confirmPassword"
          rules={[
            { required: true, message: 'Lütfen şifrenizi tekrar girin!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Şifreler uyuşmuyor!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            style={{ padding: '0.7em' }}
            placeholder="Şifre Tekrar"
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loading} className="w-full" type="primary" size="large">
            Kayıt Ol
          </Button>
        </Form.Item>
      </Form>
      <Row className="mt-24" justify={'center'}>
        <Col>
          <p className="fs-18 font-color-subtext fw-400">
            Hesabınız Var mı?{' '}
            <Link className="font-color-dark-purple fw-400 fs-18" to={'/giris'}>
              Giriş Yap
            </Link>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Register;
