import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Spin } from 'antd';
import UserService from '../services/UserService';
import { openNotification } from '../utils';
import axios from 'axios';

const LoginByToken = () => {
    const { tokenId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tokenId) {
            getToken()
        }
    }, [tokenId])

    const getToken = () => {
        let data = {
            "tokenId": tokenId
        }

        const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/login/by-tokenId';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrl,
            data: data
        };

        axios.request(config)
            .then((response: any) => {
                console.log(response)
                localStorage.setItem('accessToken', response?.data?.accessToken);
                localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
                localStorage.setItem('refreshToken', response?.data?.refreshToken);
                localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);
                userGet();
                updateMe();
            })
            .catch((e: Error) => {
                console.log(e)
                openNotification('error', 'Başarısız', 'Giriş Yapılamadı.')
                setLoading(false);
            });
    }

    const userGet = () => {
        UserService.getMe()
            .then((response: any) => {
                console.log(response);
                let role = response.data?.roles[0]?.name;

                localStorage.setItem('firstName', response.data.firstName);
                localStorage.setItem('lastName', response.data.lastName);
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('phoneNumber', response.data.phoneNumber);
                localStorage.setItem('role', response.data?.roles[0]?.name);
                localStorage.setItem('id', response.data?.id);
                localStorage.setItem('permissions', JSON.stringify(response.data?.roles[0]?.permissions));

                if (role === 'STUDENT') {
                    navigate('/');
                } else {
                    navigate('/'); // Adjust as needed
                }
            })
            .catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Giriş yapılamadı, Saha Eğitim öğrencisi değilsiniz.');
                localStorage.clear();

                console.log(e);
            });
    };

    const updateMe = () => {
        let affiliate = localStorage.getItem('affiliate');
        console.log('affiliate', affiliate);
        if (affiliate != null) {
            let postObj = {
                affiliate: affiliate,
            };
            UserService.updateMe(postObj)
                .then((response: any) => {
                    localStorage.removeItem('affiliate');
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    return (
        <Row justify={"center"} align={"middle"} style={{ height: "100vh" }}>
           <h3>Giriş yapılıyor, lütfen bekleyiniz.</h3>
        </Row>
    );
};

export default LoginByToken;
