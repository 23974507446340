import { Col, Row, Steps, Typography, Avatar } from 'antd';
import { useEffect, useState } from 'react';
import { TrophyOutlined } from '@ant-design/icons'; // Import the Trophy icon
import AccountHistoryService from '../../services/AccountHistoryService';

function ConsecutiveDaysCard() {
    const { Title, Text } = Typography;
    const [data, setData] = useState(0);

    useEffect(() => {
        getHistory();
    }, []);

    const getHistory = () => {
        AccountHistoryService.getConsecutiveLoginDays()
            .then((response: any) => {
                const data = response.data;
                setData(data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const generateSteps = () => {
        const steps = [];
        const startDay = data > 1 ? data - 1 : data;
        const stepRange = data > 1 ? 4 : 3;

        for (let i = startDay; i < startDay + stepRange; i++) {
            steps.push({
                title: `${i}. Gün`,
            });
        }

        return steps;
    };

    // Determine the avatar background color based on the number of consecutive days
    const getAvatarColor = () => {
        if (data >= 30) {
            return 'gold'; 
        } else if (data >= 15) {
            return 'silver'; 
        } else if (data >= 7) {
            return '#cd7f32'; 
        }
        return '#cd7f32'; 
    };

    return (
        <div>
            <Row justify={"space-between"} className='mt-16'>
                <Col>
                    <Title className='m-0' level={5}>Günlük Seri</Title>
                    <Text type="secondary">Uygulamayı her gün ziyaret edin</Text>
                </Col>
                {data >= 7 && (<Col>
                    {getAvatarColor() && (
                        <Avatar
                            style={{
                                backgroundColor: getAvatarColor(), // Avatar background color changes based on consecutive days
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            size={48} // Adjust the size as needed
                            icon={
                                <TrophyOutlined
                                    style={{
                                        fontSize: '24px',
                                        color: 'white', // Icon remains white
                                    }}
                                />
                            }
                        />
                    )}
                </Col>)}
            </Row>
            <div style={{ maxWidth: '100%', overflow: "auto" }}>
                <Steps
                    size="small"
                    className='mt-16'
                    current={data > 1 ? 1 : 0}
                    labelPlacement="vertical"
                    items={generateSteps()}
                />
            </div>
        </div>
    );
}

export default ConsecutiveDaysCard;
