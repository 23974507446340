import { Button, Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

interface QuizItemCardProps {
    quiz: any
}

const QuizItemCard: React.FC<QuizItemCardProps> = ({ quiz }) => {

    const [totalQuestionCount, setTotalQuestionCount] = useState(0);
    const [questionCountGroupByLesson, setQuestionCountGroupByLesson] = useState<any[]>([]);

    useEffect(() => {
        let list = groupByLessonName(quiz.questions);
        setQuestionCountGroupByLesson(list);
    }, [])


    const groupByLessonName = (array: any[]) => {
        setTotalQuestionCount(array.length)
        const lessonCounts = array.reduce((acc, item) => {
            const lessonName = item.lesson.name;
            if (!acc[lessonName]) {
                acc[lessonName] = 0;
            }
            acc[lessonName]++;
            return acc;
        }, {});

        return Object.keys(lessonCounts).map(lessonName => ({
            lesson: lessonName,
            count: lessonCounts[lessonName]
        }));
    };

    return (
        <>
            <Card style={{padding: 0}} bodyStyle={{padding: 16}}>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={5}>
                        <h3 className='m-0 p-0'>
                            {quiz?.title}
                        </h3>
                        <Row>
                            <Col>
                                <Card className='bg-cloud' bodyStyle={{padding:8}}>
                                    <div>
                                        {quiz?.duration ? `${quiz.duration} dakika` : 'Süresiz'}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <h4 className='m-0 p-0'>Toplam Soru</h4>
                        <h3 className='m-0 p-0'>
                            {totalQuestionCount}
                        </h3>
                    </Col>
                    <Col>
                        {questionCountGroupByLesson?.map((row: any) => (
                            <h4 className='m-0 p-0'>{row.lesson} - {row.count} Soru</h4>
                        ))}
                    </Col>
                    <Col>
                        <Row gutter={[16, 16]}>
                            <Col>                  
                            <NavLink to={`/quiz/${quiz?.id}`} className="mr-12">
                                <Button size="large" type='primary'>
                                    Başla
                                </Button>
                            </NavLink>
                            </Col>
                            <Col>
                                <NavLink to={`/quiz/${quiz?.id}/result`}>
                                    <Button size="large" type='default'>
                                        Sonuç
                                    </Button>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default QuizItemCard