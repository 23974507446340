import { Col, Row, Typography } from 'antd'
import React, { useEffect } from 'react'
import LearnLessonDetail from './LearnLessonDetail';

interface LearnSidebarProps {
    subjects: any;
}

const LearnSidebar: React.FC<LearnSidebarProps> = ({ subjects }) => {
    const { Title } = Typography;

    useEffect(() => {
        console.log('subjects', subjects);
    }, [])

    return (
        <>
            {subjects?.map((item: any) => (
                <>
                    <LearnLessonDetail lesson={item}></LearnLessonDetail>
                </>
            ))}
        </>
    )
}

export default LearnSidebar