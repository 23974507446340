import { useEffect, useState } from 'react';
import { Table, Form, Row, Card, Col, Button, } from 'antd';
import LessonService from '../services/LessonService';
import AssignmentService from '../services/AssignmentService';
import { formatDate, isFutureDate } from '../utils';
import AssignmentDetail from '../components/Assignments/AssignmentDetail';
import moment from 'moment';
import type { GetProp, UploadProps } from 'antd';
import {
  PlusOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  ClockCircleOutlined
} from '@ant-design/icons';
import Filter from '../components/Assignments/Filter';
import { NavLink } from 'react-router-dom';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const Exams = () => {
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any>([]);
  const [filterLessonId, setFilterLessonId] = useState<string | undefined>(undefined);

  const [filter, setFilter] = useState<any>({});

  const [form] = Form.useForm();

  const getExams = async () => {
    try {
      // const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
      setLoading(true)
      const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
      const [assignmentsResponse, lessonsResponse] = await Promise.all([
        AssignmentService.getAllMe(`?${queryString}`),
        LessonService.getAll()
      ]);

      const lessonsMap: any = new Map<string, string>();
      (lessonsResponse?.data as any).forEach((lesson: any) => {
        lessonsMap.set(lesson.id, lesson.name);
      });
      const assignmentData = (assignmentsResponse?.data as any).map((assignment: any) => {
        const lessonName = lessonsMap.get(assignment.lessonId);
        return {
          ...assignment,
          lessonName,
          assignmentMoment: moment(assignment.assignmentDate)
        };

      });
      setLessons(lessonsResponse?.data);
      setAssignments(assignmentData)
      setLoading(false)

      // setLessons(assignmentData);
    } catch (error) {
      console.error(error);
      setLoading(false)

    }
  };

  useEffect(() => {
    getExams();
  }, [filter]);

  const filteredAssignments = assignments.filter(assignment =>
    filterLessonId ? assignment.lessonId.toString() === filterLessonId : true
  );

  const columns = [
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => {
        const isPastDue = !isFutureDate(record.dueDate);
        return (
          <>
            {record?.responseContent || record?.responseImage ? (
              <>
                {record.isDone ? (
                  <Row align={'middle'}>
                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24, marginRight: 5 }} /> Tamamlandı
                  </Row>
                ) : (
                  <Row align={'middle'}>
                    <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24, marginRight: 5 }} /> Eksik veya Hatalı
                  </Row>
                )}
              </>
            ) : (
              <Row align={'middle'}>
                {isPastDue ? (
                  <><CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24, marginRight: 5 }} /> Süresi Geçti</>
                ) : (
                  <><ClockCircleOutlined style={{ fontSize: 24, marginRight: 5, color: "#FED449" }} /> Beklemede</>
                )}
              </Row>
            )}
          </>
        );
      }
    },
    {
      title: 'Ders',
      dataIndex: 'lessonName',
      key: 'lessonName',
    },
    {
      title: 'Ödev',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Teslim Tarihi',
      dataIndex: 'dueDate',
      render: (text: any) => (
        <>
          {formatDate(text)}
        </>
      )
    },
    {
      title: 'Ödev Tarihi',
      dataIndex: 'createdAt',
      render: (text: any) => (
        <>
          {formatDate(text)}
        </>
      )
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (_: any, record: any) => (
        <>
          <AssignmentDetail item={record} getTrigger={getExams} />
        </>
      )
    },

  ];

  const handleOnChangeFilter = (values: any) => {
    console.log(values);
    setFilter((prev: any) => {
      const newFilter = { ...prev };

      if (values.startDueDate !== undefined) {
        newFilter.startDueDate = values.startDueDate;
      } else {
        delete newFilter.startDueDate; // Eğer undefined ise, filter objesinden bu anahtarı çıkarıyoruz.
      }

      if (values.endDueDate !== undefined) {
        newFilter.endDueDate = values.endDueDate;
      } else {
        delete newFilter.endDueDate;
      }

      return newFilter;
    });
  };

  return (
    <>
      <Card bodyStyle={{ paddingTop: '0px' }} title="Ödevler"
        extra={<Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
          <Col>
            <NavLink to="/" >
              <Button size="large">
                Geri
              </Button>
            </NavLink>
          </Col>
        </Row>}>
        <Table loading={loading} dataSource={filteredAssignments} columns={columns} rowKey="id" />
      </Card>
    </>
  );
};

export default Exams;
