import { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, DatePicker, Button, Row, Col, Drawer } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { openNotification } from '../../utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import liveLessonService from '../../services/LiveLessonService';
import LessonService from '../../services/LessonService';

const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

const LiveLessonCreate = ({ liveLessonCredit, getLiveLessons }: any) => {
  const [lessons, setLessons] = useState([])
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    getLessonList();
  }, [])

  const showRequestModal = () => {
    setIsRequestModalOpen(true);
  };

  // Close modal function
  const closeRequestModal = () => {
    setIsRequestModalOpen(false);
  };

  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  const getLessonList = () => {
    LessonService.getAll()
        .then((response: any) => {
            setLessons(response.data);
        })
        .catch((e: Error) => {
            console.log(e);
        });
};

  const handleRequestSubmit = async (values: any) => {
    setLoading(true); // Set loading to true when submission starts
    const localDate = dayjs(values.liveLessonDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');

    let postObj: any = {
      "note": values.note,
      "liveLessonDate": localDate,
      "lessonId": values.lessonId
    };

    liveLessonService.create(postObj)
      .then((response: any) => {
        form.resetFields();
        openNotification('success', 'Başarılı', 'Ders talebi Oluşturuldu.');
        getLiveLessons(); // Update calendar with new lessons
        setIsRequestModalOpen(false); // Close modal on success
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when submission ends
      });
  };

  return (
    <>
      <Button type="primary" disabled={liveLessonCredit === 0} size="large" onClick={showRequestModal}>
        Canlı Ders Talep Et
      </Button>
      <Drawer
        title="Canlı Ders Talep Et"
        open={isRequestModalOpen}
        onClose={closeRequestModal} 
        footer={null}
        width={480}
      >
        <Form form={form} layout="vertical" onFinish={handleRequestSubmit}>
          <Form.Item
            name="lessonId"
            label="Ders"
            rules={[{ required: true, message: 'Lütfen ders seçiniz' }]}
          >
            <Select
              placeholder="Ders"
              style={{ width: '100%' }}
              showSearch
              size="large" // Set Select size to large
              optionFilterProp="children"
              filterOption={(input, option) => {
                const normalizeText = (text: string) =>
                  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                return normalizeText(option?.children as unknown as string).includes(normalizeText(input));
              }}
              filterSort={(optionA, optionB) => {
                const normalizeText = (text: string) =>
                  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                return normalizeText(optionA?.children as unknown as string).localeCompare(normalizeText(optionB?.children as unknown as string));
              }}
            >
              {lessons.map((lesson: any) => (
                <Option key={lesson.id} value={lesson.id}>
                  {lesson.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="liveLessonDate"
            label="Ders Tarihi ve Saati"
            rules={[{ required: true, message: 'Lütfen bir tarih seç!' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY HH:mm"
              placeholder="Tarih Seçiniz"
              locale={locale}
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
              disabledDate={disabledDate}
              size="large" // Set DatePicker size to large
            />
          </Form.Item>
          <Form.Item name="note" label="Not">
            <Input placeholder="Not" size="large" />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit" size="large" loading={loading}> {/* Loading state for the button */}
                Talebi Gönder
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default LiveLessonCreate;
