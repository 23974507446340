import { Card, Col, Progress, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import VideoHistoryService from '../../services/VideoHistoryService';
import { Link, NavLink } from 'react-router-dom';
import VimeoPlayer from '../VimeoPlayer';
import Meta from 'antd/es/card/Meta';

function VideoHistory() {
    const { Title,Text } = Typography;
    const [loading, setLoading] = useState<any>(false)
    const [data, setData] = useState([])

    useEffect(() => {
        getAllVideoHistoryMe();
    }, [])

    const calcPercent = (video: any) => {
        return Math.ceil((video?.duration / video?.videoDuration) * 100)
    }

    const getAllVideoHistoryMe = () => {
        setLoading(true);
        VideoHistoryService.getAllMe()
            .then((response: any) => {
                const data = response.data;
                setLoading(false);
                setData(data)
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };
    return (
        <Card loading={loading} className='pt-0' title="Öğrenmeye Devam Edin">
            <Row gutter={[24, 24]}>
                {data.map((video: any, index: number) => (
                    (index < 4) && <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <NavLink to={`/video/${video?.videoId}/subject/${video?.subjectId}/course/${video.courseId}/unit/${video?.unitId}/lesson/${video?.lessonId}#duration=${video?.duration}`}>
                            <Card

                            style={{height: '100%'}}
                                bodyStyle={{ padding: 16, minHeight: 90 }}
                                cover={
                                    <img src={video?.videoCover} />
                                }
                            >
                                <Text className='d-block mt-16' type="secondary">{video?.courseName}</Text>
                                <Title className='m-0' level={5}>{video?.videoTitle}</Title>
                                <Text>{calcPercent(video)}%</Text>
                                <Progress
                                    strokeColor={'#935ac0'}
                                    percent={calcPercent(video)}
                                    size="default"
                                    showInfo={false}
                                />
                            </Card>
                        </NavLink>
                    </Col>
                ))}
            </Row>
        </Card>
    )
}

export default VideoHistory