import { http } from "../http-common";
import { IQuestionResponse } from "../types/Question";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('question.list')) {
        return http.get<IQuestionResponse>(`/question`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('question.show')) {
        return http.get<IQuestionResponse>(`/question/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getQuestion = (id: any) => {
    if (isAuthorized('question.show')) {
        return http.get<IQuestionResponse>(`/question/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    if (isAuthorized('question.download')) {
        return http.get<any>(`/question/download?file=${fileName}`, { responseType: 'arraybuffer' });

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const QuestionService = {
    getAll,
    get,
    getQuestion,
    getFile,
}

export default QuestionService;