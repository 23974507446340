import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QuizService from '../services/QuizService';
import { Badge, Button, Card, Col, Radio, Row, Space, Statistic, Tag, Typography, Image, Pagination, RadioChangeEvent, Popconfirm, Empty } from 'antd';
import type { PaginationProps } from 'antd';
import Countdown from 'antd/es/statistic/Countdown';
import ExamImg from '../assets/Dashboard/exam.jpeg';
import QuestionService from '../services/QuestionService';
import type { CountdownProps } from 'antd';
import QuizAnswersService from '../services/QuizAnswersService';
import QuizResult from '../components/Quizzes/QuizResult';

const { Title } = Typography;

function QuizResultPage() {
    const { id } = useParams();
    const [quiz, setQuiz] = useState<any>();
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [resultStep, setResultStep] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [duration, setDuration] = useState<any>(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [quizResult, setQuizResult] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [isPreviouslyStarted, setIsPreviouslyStarted] = useState(false);

    const getQuiz = () => {
        setLoading(true);
        QuizService.get(id)
            .then((response: any) => {
                let data = response.data;
                data.questions = questionsSortByLessonName(data?.questions);
                questionsGroupByLessonName(data?.questions);
                setQuiz(data);
                setLoading(false);
                getQuizResult();
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    useEffect(() => {
        const previouslyStartedQuiz = localStorage.getItem(`quiz_${id}`);
        if (previouslyStartedQuiz) {
            setIsPreviouslyStarted(true);
        }
        getQuiz();
    }, [id]);

    useEffect(() => {
        if (isStart) {
            setDuration(Date.now() + 1000 * 60 * quiz?.duration + 1000);
            setStartTime(Date.now());
            getFile();
            localStorage.setItem(`quiz_${id}`, 'started');
        }
    }, [isStart, quiz?.duration]);

    useEffect(() => {
        getFile();
    }, [currentQuestion]);

    const questionsSortByLessonName = (questions: any[]) => {
        questions = questions.map((q: any) => ({
            ...q,
            question: {
                ...q.question,
                answer: null,
                file: null,
            },
        }));
        questions = questions.sort((a: any, b: any) => a.question.id - b.question.id);
        return questions.sort((a: any, b: any) => a.lesson.name.localeCompare(b.lesson.name));
    };

    const questionsGroupByLessonName = (questions: any[]) => {
        const groupedQuestions = questions.reduce((acc: any, current: any) => {
            const lessonName = current.lesson.name;
            if (!acc[lessonName]) {
                acc[lessonName] = {
                    id: current.lesson.id,
                    name: current.lesson.name,
                    questions: [],
                };
            }
            acc[lessonName].questions.push(current.question);
            return acc;
        }, {});

        let result: any = Object.values(groupedQuestions);

        result = result.sort((a: any, b: any) => a.name.localeCompare(b.name));

        result.forEach((lesson: any) => {
            lesson.questions.sort((a: any, b: any) => a.id - b.id);
        });

        setLessons(result);
    };

    const onChange = (index: any) => (e: RadioChangeEvent) => {
        let selected = e.target.value;
        let quizData = { ...quiz };
        quizData.questions[index].question.answer = selected;
        setQuiz(quizData);
    };

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isStart) {
                const confirmationMessage = 'Are you sure you want to leave? Your progress will be lost.';
                e.preventDefault(); // Modern tarayıcılar için
                e.returnValue = confirmationMessage; // Eski tarayıcılar için
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isStart]);

    const renderQuestionList = (questions: any[]) => {
        if (!questions) {
            return false;
        }
        let lessonName = "";
        return (
            <>
                {questions.map((row: any, index: any) => {
                    const showLessonName = lessonName !== row.lesson.name;
                    lessonName = row.lesson.name;
                    return (
                        <div key={row.question.id}>
                            {showLessonName && (
                                <Row>
                                    <Col span={24}>
                                        <h3>{row.lesson.name}</h3>
                                    </Col>
                                </Row>
                            )}
                            <Row align={'middle'} key={row.question.id}>
                                <Col span={1}>
                                    <b>{index + 1}</b>
                                </Col>
                                <Col span={23}>
                                    <Card className='antd-card-body-p-0'>
                                        <div className='mb-16 mt-8'>
                                            {renderOptionsSider(row.question, index)}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderOptionsSider = (question: any, index: any) => {
        const options = ['A', 'B', 'C', 'D', 'E'].slice(0, question?.answerType);
        return (
            <Button type="link" onClick={() => { setCurrentQuestion(index + 1) }} style={{ width: '100%', padding: 0 }}>
                <Radio.Group onChange={onChange(index)} value={question?.answer}>
                    <Space>
                        {options.map(option => (
                            <Radio className='border-cloud pl-8 py-8 border-radius-lg' key={option} value={option}>
                                {option}
                            </Radio>
                        ))}
                        <Radio className='border-cloud pl-8 py-8 border-radius-lg' key={null} value={null}>Boş</Radio>
                    </Space>
                </Radio.Group>
            </Button>
        );
    };

    const renderLessonList = (lessons: any[]) => {
        return (
            <Row gutter={[16, 16]}>
                {lessons?.map((lesson: any, index: any) => (
                    <Col>
                        <Badge color="#fed449" key={index} count={lesson.questions.length}>
                            <Tag color="default">{lesson.name}</Tag>
                        </Badge>
                    </Col>
                ))}
            </Row>
        );
    };


    const getFile = async () => {
        try {
            setFile(null);
            let fileName = quiz?.questions[currentQuestion - 1]?.question?.question;
            if (fileName) {
                const response = await QuestionService.getFile(fileName);
                const pdfBlob = new Blob([response?.data], { type: 'application/jpg' });
                let fileData = URL.createObjectURL(pdfBlob);

                // Eski blob URL'sini serbest bırak
                if (file) {
                    URL.revokeObjectURL(file);
                }

                setFile(fileData);
                let quizData = { ...quiz };
                quizData.questions[currentQuestion - 1].question.file = response?.data;
                setQuiz(quizData);
            }
        } catch (e) {
            console.error('Dosya getirilirken hata oluştu:', e);
        }
    };

    const getQuizResult = () => {
 
        QuizAnswersService.getQuizResult(id)
            .then((response: any) => {
                let data = response.data;
                setIsSubmit(false);
                setQuizResult(data);
                setResultStep(true);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const onChangeCountDown: CountdownProps['onChange'] = (val: any) => {
        // console.log("onChangeCountDown", (parseInt(val) / 1000 / 60));
    };

    const handleQuestionClick = (index: number) => { };

    return (
        <>
            <Card loading={loading}>
                <Row gutter={[32, 32]} justify={"space-between"}>
                    <Col>
                        <Title className="m-0 p-0" level={4}>#{quiz?.id} - {quiz?.title}</Title>
                        {(isStart && !resultStep) && <Popconfirm
                            title="Sınavı bitirmek istediğinizden emin misiniz?"
                            onConfirm={getQuizResult}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button disabled={isSubmit} type="primary" className="mr-16">
                                Sınavı Tamamla
                            </Button>
                        </Popconfirm>}
                    </Col>
                    <Col>
                        <Title className="m-0 p-0" level={5}>Dersler</Title>
                        {renderLessonList(lessons)}
                    </Col>
                    <Col>
                        <Statistic title="Toplam Soru" value={`${quiz?.questions.length}`} />
                    </Col>
                    <Col>
                        {(isStart && duration && !resultStep) ? <Countdown title="Sınav Süresi" value={duration} onChange={onChangeCountDown} format="HH:mm:ss" /> : <Statistic title="Sınav Süresi" value={`${quiz?.duration} dakika`} />}
                    </Col>
                </Row>
            </Card>
            {(resultStep) && <QuizResult quizResult={quizResult} questions={quiz?.questions} onQuestionClick={handleQuestionClick} />}
        </>
    );
}

export default QuizResultPage;
