import { useLocation } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import ProfileLink from './ProfileLink'; // Assuming this is your ProfileLink component
import { useEffect, useState } from 'react';
const { Title } = Typography;

// Page titles map, including some dynamic path segments
const pageTitles: { [key: string]: string } = {
    "/": "Genel Görünüm",
    "/profile": "Profil",
    "/course": "Kurs Listesi",
    "/my-course": "Kurslarım",
    "/reminder": "Canlı Ders",
    "/live-lesson": "Canlı Ders",
    "/student-questions": "Sorular",
    "/exams": "Ödevler",
    "/support": "Destek",
    "/quizzes": "Sınavlar",
};

// Function to get the title based on the path
const getTitleFromPath = (pathname: string): string => {
    if (pageTitles[pathname]) return pageTitles[pathname];
    if (pathname.startsWith("/course/")) return "Kurs Detay";
    if (pathname.startsWith("/video/")) return "Kurs Detay";
    if (pathname.startsWith("/quiz/")) return "Sınav Detay";
    if (pathname.startsWith("/document/")) return "Doküman";
    return "";
};

const PageHeader: React.FC = () => {
    const [firstName, setFirstName] = useState<string | null>()

    useEffect(() => {
        let firstName: string | null = localStorage.getItem('firstName')
        setFirstName(firstName)
    }, [])


    const location = useLocation();
    const pathname = location.pathname;
    const title = getTitleFromPath(pathname);

    return (
        <>
            {pathname === "/" ? (
                <Row gutter={[16, 0]} justify={"space-between"} className='mb-16'>
                    <Col>
                        <Title className='m-0 mt-8' level={3}><span style={{ fontWeight: 100 }}>Hoşgeldin </span> {firstName}</Title>
                    </Col>
                    <Col>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <NavLink to="/course" >
                                    <Button type="primary" size="large">Kurs Satın Al</Button>
                                </NavLink>
                            </Col>
                            <Col>
                                <NavLink to="/profile" >
                                    <ProfileLink></ProfileLink>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row gutter={[16, 0]} justify={"space-between"} className="mb-16">
                    <Col>
                        <Title className="m-0 mt-8" level={3}>
                            {title}
                        </Title>
                    </Col>
                    <Col>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <NavLink to="/profile">
                                    <ProfileLink />
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default PageHeader;
