import { http } from "../http-common";
import { isAuthorized, openNotification } from "../utils";

const getAll = (id: any) => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/student/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAllMe = () => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/me`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAllMeStatistics = () => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/me/statistics`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFiltered = (id: any, lessonId: any) => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/student/${id}?lessonId=${lessonId}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: { // Axios'un URL parametrelerini eklemek için kullandığı obje
            studentId: data.studentId,
            lessonId: data.lessonId,
            question: data.question
        }
    };

    if (isAuthorized('studentquestion.create')) {
        return http.post<any>(`/student-questions`, formData, config);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    if (isAuthorized('studentquestion.download')) {
        return http.get<any>(`/student-questions/download?file=${fileName}`, { responseType: 'arraybuffer' });

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: any) => {
    if (isAuthorized('studentquestion.update')) {
        return http.patch<any>(`/student-questions/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const StudentQuestionService = {
    create,
    getAll,
    getAllMe,
    getFiltered,
    update,
    getFile,
    getAllMeStatistics
}

export default StudentQuestionService;