import { Col, Row, Tooltip, Typography } from 'antd'
import React from 'react'
import HomeworkIcon from '../../assets/dashboard/homework.svg'
import QuestionIcon from '../../assets/dashboard/question.svg'
import QuizIcon from '../../assets/dashboard/quiz.svg'
import { NavLink } from 'react-router-dom'

function Shortcut() {
    const { Title } = Typography;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                <NavLink to="/student-questions" >
                    <div className='shortcut-item bg-cloud'>
                        <Row justify={"center"} align={"middle"}>
                            <img src={QuestionIcon} />
                        </Row>
                        <Row justify={"center"} align={"middle"}>
                            <Title className='m-0' level={5}>Soru Sor</Title>
                        </Row>
                    </div>
                </NavLink>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                <NavLink to="/quizzes" >
                    <div className='shortcut-item bg-cloud'>
                        <Row justify={"center"} align={"middle"}>
                            <img src={QuizIcon} />
                        </Row>
                        <Row justify={"center"} align={"middle"}>
                            <Title className='m-0' level={5}>Sınav</Title>
                        </Row>
                    </div>
                </NavLink>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                <NavLink to="/exams" >
                    <div className='shortcut-item bg-cloud'>
                        <Row justify={"center"} align={"middle"}>
                            <img src={HomeworkIcon} />
                        </Row>
                        <Row justify={"center"} align={"middle"}>
                            <Title className='m-0' level={5}>Ödev</Title>
                        </Row>
                    </div>
                </NavLink>
            </Col>
        </Row >
    )
}

export default Shortcut