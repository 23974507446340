import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import QuestionService from '../services/QuestionService';
import { Row, Image, Col, Radio, RadioChangeEvent, Space, Button, Pagination, Card } from 'antd';
import SubjectService from '../services/SubjectService';
import { openNotification } from '../utils';

interface FilesUrl {
    [key: string]: string;  // Her anahtarın string ve her değerin string olduğu bir obje tipi tanımlıyoruz.
}

const Questions: React.FC = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [questions, setQuestions] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<Record<string, string>>({});
    const [filesUrl, setFilesUrl] = useState<any>({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);  // Aktif soru indeksi
    const [feedback, setFeedback] = useState<string | null>(null);
    const navigate = useNavigate()

    const onChange = (questionId: string) => (e: RadioChangeEvent) => {
        setSelectedValue(prev => ({ ...prev, [questionId]: e.target.value }));
    };

    const renderOptions = (question: any) => {
        const options = ['A', 'B', 'C', 'D', 'E'].slice(0, question?.answerType);
        return (
            <Radio.Group onChange={onChange(question?.id)} value={selectedValue[question?.id]}>
                <Space>
                    {options.map(option => (
                        <Radio className='border-onyx px-16 py-8 border-radius-lg' key={option} value={option}>{option})</Radio>
                    ))}
                </Space>
            </Radio.Group>
        );
    };

    const handlePageChange = (page: number) => {
        setCurrentQuestionIndex(page - 1);  // Sayfa numarası 1'den başladığı için 1 çıkartıyoruz
        setFeedback(null);
    };

    const loadFiles = async (questions: any[]) => {
        const urls: FilesUrl = {};  // FilesUrl tipinde bir obje oluşturuyoruz.
        for (let question of questions) {
            try {
                const response = await QuestionService.getFile(question.question);
                const pdfBlob = new Blob([response?.data], { type: 'application/pdf' });
                urls[question.id] = URL.createObjectURL(pdfBlob);  // Bu satır artık hata vermeyecek.
            } catch (error) {
                console.error('Error downloading file:', error);
                urls[question.id] = ''; // Hata durumunda boş bir string atıyoruz.
            }
        }
        setFilesUrl(urls);
    };

    useEffect(() => {
        getService();
    }, [id]);

    const getService = async () => {
        setLoading(true);
        try {
            const response = await SubjectService.get(id);
            const data : any = response?.data.questions;
            setQuestions(data);
            await loadFiles(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const checkAnswer = (question: any) => {
        if (selectedValue[question.id] === question.correctAnswer) {
            openNotification('success', 'Doğru!', 'Doğru cevap!');
            setFeedback("Doğru Cevap!");
        } else {
            openNotification('error', 'Yanlış', `Yanlış cevap! Doğru cevap: ${question.correctAnswer}`);
            setFeedback(`Yanlış Cevap! Doğru cevap: ${question.correctAnswer}`);
        }
    };

    return (
        <>
            <Row justify={'end'}>
                <Col>
                    <Button onClick={() => navigate(-1)} className='fw-700'>
                        Geri
                    </Button>
                </Col>
            </Row>
            <Row className='mt-64' gutter={[24, 100]}>
                <Col span={24}>
                    <Card loading={loading}>
                        <React.Fragment key={questions[currentQuestionIndex]?.id}>
                            <Row justify="center">
                                <Col>
                                    {filesUrl[questions[currentQuestionIndex]?.id] && (
                                        <Image src={filesUrl[questions[currentQuestionIndex]?.id]} fallback="error.png" />
                                    )}
                                </Col>
                            </Row>
                            <Row className="mt-64" justify="center">
                                <Col>{renderOptions(questions[currentQuestionIndex])}</Col>
                            </Row>
                            <Row align={'middle'} gutter={[24, 24]} justify="end">
                                <Col>
                                    <span>{feedback}</span>
                                </Col>
                                <Col>
                                    <Button onClick={() => checkAnswer(questions[currentQuestionIndex])} className="btn fw-700 font-color-white bg-saha-purple">
                                        Kontrol Et
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Card>
                </Col>
            </Row>
            <Pagination
                current={currentQuestionIndex + 1}
                total={questions.length}
                pageSize={1}
                onChange={handlePageChange}
                className="mt-24"
            />
        </>
    )
}

export default Questions