import React from 'react';
import { Button, Tooltip } from 'antd';

interface LiveLessonLinkProps {
  link?: string | null;
}

const LiveLessonLink: React.FC<LiveLessonLinkProps> = ({ link }) => {
  if (!link) {
    return null;
  }

  return (
    <Tooltip title="Canlı Ders">
      <Button
      style={{padding: '0', margin: '0'}}
        type="link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Bağlan
      </Button>
    </Tooltip>
  );
};

export default LiveLessonLink;
