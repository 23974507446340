import React, { useEffect, useState } from 'react'
import QuizService from '../services/QuizService';
import QuizItemCard from '../components/Quizzes/QuizItemCard';
import { Button, Card, Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';

const Quizzes = () => {
    const [loading, setLoading] = useState<boolean>()
    const [quizzes, setQuizzes] = useState<any[]>([])

    useEffect(() => {
        getQuizzes()
    }, [])

    const getQuizzes = () => {
        setLoading(true)
        QuizService.getAll()
            .then((response: any) => {
                let data: any = response.data;
                setQuizzes(data);
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            <Card bodyStyle={{ paddingTop: '0px' }} title="Sınavlar"
                extra={<Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
                    <Col>
                        <NavLink to="/" >
                            <Button size="large">
                                Geri
                            </Button>
                        </NavLink>
                    </Col>
                </Row>} loading={loading}>
                <Row gutter={[24, 24]}>
                    {quizzes?.map((quiz: any) => (
                        <>
                            <Col span={24}>
                                <QuizItemCard quiz={quiz}></QuizItemCard>
                            </Col>
                        </>
                    ))}
                </Row>
            </Card>
        </>
    )
}

export default Quizzes