import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Result, Spin } from 'antd';

const PaymentCallbackSuccess: React.FC = () => {
  const navigate = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setTimeout(() => {
      navigate('/my-course');
    }, 3000);
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Card style={{ maxWidth: 600, width: '100%', textAlign: 'center' }}>
      <Result
        status="success"
        title="Ödeme Başarılı"
        subTitle="Ödemeniz Alindi. Paketlerinize yönlendiriliyorsunuz."
      />
      <Spin indicator={antIcon} style={{ marginTop: 16 }} />
    </Card>
  </div>
  );
};

export default PaymentCallbackSuccess;
