import { Card, Checkbox, Col, Collapse, Row, Space, Tooltip, Typography } from 'antd';
import React, { useRef, useEffect } from 'react';
import { FilePdfOutlined, FileOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined, RightOutlined } from '@ant-design/icons';
import { NavLink, useParams } from 'react-router-dom';
import { formatDuration, isValidUrlPattern } from '../../utils';

interface LearnVideoListProps {
    subject: any;
    index: number;
}

const LearnVideoList: React.FC<LearnVideoListProps> = ({ subject, index }) => {
    const { id, subjectId, courseId, unitId }: any = useParams();
    const { Title, Text } = Typography;
    const pattern = /^\/course\/\d+\/detail$/;

    // Ref to store references to video elements
    const videoRefs = useRef<Record<number, HTMLDivElement | null>>({});

    useEffect(() => {
        // Scroll the current video into view
        const currentVideoId = parseInt(id);
        if (currentVideoId) {
            const currentVideoRef = videoRefs.current[currentVideoId];
            if (currentVideoRef) {
                currentVideoRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [id]);

    return (
        <>
            {subject?.videos?.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((video: any) => (
                <Space
                    key={video.id}
                    direction="vertical"
                    style={{ background: 'white', width: '100%', paddingTop: 8, paddingBottom: 8 }}
                    className={video?.id == id ? 'current-video' : ''}
                    ref={(el) => {
                        // Store the reference to the video element
                        if (el) {
                            videoRefs.current[video.id] = el;
                        }
                    }}
                >
                    <span style={{ padding: "16px 0px" }}
                    >
                        <Space direction="vertical">
                            <p style={{ padding: 16, display: "inline" }}
                            >
                                <NavLink
                                    replace={true}
                                    to={(isValidUrlPattern(pattern) || unitId) ? `/video/${video?.id}/subject/${subject?.id}/course/${courseId}/unit/${subject?.unitId}/lesson/${subject?.lessonId}` : ""}
                                >
                                    <span>
                                        {(isValidUrlPattern(pattern) || unitId) && <Checkbox style={{ marginRight: 8 }} checked={video?.isCompleted}></Checkbox>}
                                        <PlaySquareOutlined style={{ color: "#935ac0", marginRight: 8 }} />
                                        <Text style={{ fontWeight: 500 }}>{video?.title}</Text>
                                    </span>

                                </NavLink>
                                {video?.documents?.map((document: any) => (
                                    <NavLink replace={true} target='_blank' to={courseId ? `/document/${document?.id}` : ""}>
                                        <Tooltip title={document?.title}>
                                            <FilePdfOutlined style={{ color: "#935ac0", marginLeft: 16, marginRight: 8 }} />
                                        </Tooltip>
                                    </NavLink>
                                ))}
                            </p>
                        </Space>
                    </span>

                </Space>
            ))}
        </>
    );
};

export default LearnVideoList;
