import { http } from "../../../http-common";
import { IReminderRequest, IReminderRequestEdit, IReminderResponse } from "../Types/Reminder";


const getAll = (query:string) => {
    return http.get<IReminderResponse>(`/accounts/me/reminders/all${query}`, );
};

const create = (data: IReminderRequest) => {
    return http.post<IReminderRequest>(`/accounts/me/reminders`, data);
};

const update = (id: number, data: IReminderRequestEdit) => {
    return http.put<IReminderRequest>(`/reminders/${id}`, data);
};

const remove = (id: number) => {
    return http.delete<IReminderRequest>(`/reminders/${id}`);
};

const ReminderService = {
    getAll,
    create,
    update,
    remove
};

export default ReminderService;