import { Calendar, Card, Row, Col, ConfigProvider, Button, Spin } from 'antd';
import { useState, useEffect } from 'react';
import LessonService from '../services/LessonService';
import moment from 'moment';
import dayjs from 'dayjs';
import LiveLessonDetail from '../components/LiveLesson/LiveLessonDetail';
import trTR from 'antd/lib/locale/tr_TR';
import Filter from '../components/P2PLesson/Filter';
import { NavLink } from 'react-router-dom';
import UserService from '../services/UserService';
import LiveLessonCreate from '../components/LiveLesson/LiveLessonCreate';
import LiveLessonService from '../services/LiveLessonService';

const P2PLesson = () => {
  const [lessons, setLessons] = useState([]);
  const [liveLessonCredit, setLiveLessonCredit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allLessons, setAllLessons] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    startLiveLessonDate: dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endLiveLessonDate: dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
  });

  const getLiveLessons = async () => {
    try {
      const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
      setLoading(true);
      const [liveLessonsResponse, lessonsResponse] = await Promise.all([
        LiveLessonService.getAll(`?${queryString}`),
        LessonService.getAll()
      ]);

      const lessonsMap = new Map<string, string>();
      (lessonsResponse?.data as any).forEach((lesson: any) => {
        lessonsMap.set(lesson.id, lesson.name);
      });

      const lessonsData = (liveLessonsResponse?.data as any).map((liveLesson: any) => {
        const lessonName = lessonsMap.get(liveLesson.lessonId);
        return {
          ...liveLesson,
          lessonName,
          liveLessonMoment: moment(liveLesson.liveLessonDate)
        };
      });
      setAllLessons(lessonsResponse?.data);
      setLessons(lessonsData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    var now = dayjs();
    onPanelChange(now);
    getMe();
  }, []);

  const getListData = (x: any) => {
    const dateData: any = lessons || [];
    let matchedItems = [];
    for (let item of dateData) {
      if (x.format('YYYY-MM-DD') === dayjs(item.liveLessonDate).format('YYYY-MM-DD')) {
        matchedItems.push({ type: 'success', item: item, id: item.id });
      }
    }

    return matchedItems.length > 0 ? matchedItems : [];
  };

  const dateCellRender = (currentDate: any) => {
    const listData = getListData(currentDate);
    return (
      <ul className="events" style={{ listStyleType: 'none', paddingLeft: 5 }}>
        {listData.map((lesson: any, index) => (
          <li key={index}>
            {lesson.item && (
              <LiveLessonDetail item={lesson} getLiveLessons={getLiveLessons} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  const handleOnChangeFilter = (values: any) => {
    setFilter((prev: any) => {
      const newFilter = { ...prev };

      if (values.student !== undefined) {
        newFilter.studentId = values.student;
      } else {
        delete newFilter.studentId;
      }

      if (values.teacher !== undefined) {
        newFilter.userId = values.teacher;
      } else {
        delete newFilter.userId;
      }

      if (values.lesson !== undefined) {
        newFilter.lessonId = values.lesson;
      } else {
        delete newFilter.lessonId;
      }

      if (values.isApprovel !== undefined) {
        newFilter.isApprovel = values.isApprovel;
      } else {
        delete newFilter.isApprovel;
      }

      if (values.isComplete !== undefined) {
        newFilter.isComplete = values.isComplete;
      } else {
        delete newFilter.isComplete;
      }

      return newFilter;
    });
  };

  const getMe = () => {
    UserService.getMe()
      .then((response: any) => {
        localStorage.setItem('liveLessonCredit', response.data.liveLessonCredit);
        setLiveLessonCredit(response.data.liveLessonCredit);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const onPanelChange = (value: any) => {
    const startOfMonth = value.startOf('month').format('YYYY-MM-DDT00:00:00');
    const endOfMonth = value.endOf('month').format('YYYY-MM-DDT23:59:59');

    setFilter((prev: any) => ({
      ...prev,
      startLiveLessonDate: startOfMonth,
      endLiveLessonDate: endOfMonth,
    }));
  };

  useEffect(() => {
    getLiveLessons();
  }, [filter]);

  return (
    <ConfigProvider locale={trTR}>
      <Card
        bodyStyle={{ paddingTop: '0px' }}
        title="Canlı Dersler"
        extra={
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col>
              <b>Canlı Ders Hakkı:</b> {liveLessonCredit}
            </Col>
            <Col>
              <LiveLessonCreate
                liveLessonCredit={liveLessonCredit}
                allLessons={allLessons}
                getLiveLessons={getLiveLessons}
              />
            </Col>
            <Col>
              <Filter onChangeFilter={handleOnChangeFilter} lessons={allLessons} />
            </Col>
            <Col>
              <NavLink to="/">
                <Button size="large">Geri</Button>
              </NavLink>
            </Col>
          </Row>
        }
      >
        <Spin spinning={loading}>
          <Calendar cellRender={dateCellRender} mode="month" onPanelChange={onPanelChange} />
        </Spin>
      </Card>
    </ConfigProvider>
  );
};

export default P2PLesson;
