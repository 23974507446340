import { Col, Drawer, Row, Form, Input, Button, notification } from 'antd';
import React from 'react';
import NoteService from '../Service/NoteService';
import { INoteRequest, INoteRequestEdit } from '../Type/Note';

interface NoteDrawerProps {
    selectedNote: any;
    onClose: any;
    visible: any;
    refreshTrigger: () => void;
}

const NoteDrawer: React.FC<NoteDrawerProps> = ({ selectedNote, onClose, visible, refreshTrigger }) => {

    const [form] = Form.useForm();

    const handleUpdateSubmit = (values: any) => {
        const updatedData: INoteRequestEdit = {
            content: values.content,
        };

        NoteService.update(selectedNote.id, updatedData)
            .then(() => {
                notification.success({
                    message: 'Başarılı',
                    description: 'Not başarıyla kaydedildi.',
                });
                refreshTrigger(); // Listeyi güncelle
                onClose(); // Drawer'ı kapat
            })
            .catch((error: Error) => {
                notification.error({
                    message: 'Hata',
                    description: 'Kaydetme sırasında bir hata oluştu.',
                });
                console.error('Kaydetme sırasında hata:', error);
            });
    };

    return (
        <div>
            <Drawer
                title={`Not #${selectedNote?.id}`}
                placement="right"
                onClose={onClose}
                visible={visible}
                width={400}
            >
                {selectedNote && (
                    <>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleUpdateSubmit}
                            initialValues={{
                                content: selectedNote.content,
                            }}
                        >
                            <Form.Item name="content" label="Not" rules={[{ required: true, message: 'Lütfen Not girin' }]}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Kaydet
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </Drawer>
        </div>
    );
};

export default NoteDrawer;
