import {http} from "../http-common";
import { ILessonRequest, ILessonResponse } from "../types/Lesson";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('lesson.list')) {
        return http.get<ILessonResponse>(`/students/lesson`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('lesson.show')) {
        return http.get<ILessonResponse>(`/lesson/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const LessonService = {
    getAll,
    get,
}

export default LessonService;