import React from 'react';
import { Button, Col, Form, Row, Typography, Layout, Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';

const { Title, Paragraph } = Typography;

const ForgotToNewPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const [email, setEmail] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    const onFinishOtpAndNewPassword = (values: { newPassword: string; confirmPassword: string }) => {
        setLoading(true);

        let userData = {
            otp: otp,
            newPassword: values.newPassword,
            email: email,
        };

        const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/reset-password';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrl,
            data: userData,
        };

        axios
            .request(config)
            .then((response: any) => {
                console.log(response);
                openNotification('success', 'Başarılı', 'Şifreniz başarıyla yenilendi. Giriş yapabilirsiniz.');
                setIsPasswordChanged(true);
                setTimeout(() => {
                    // navigate('/giris');
                    setLoading(false);
                }, 3000);
            })
            .catch((e: any) => {
                console.log(e);
                if (e.response && e.response.status === 400 && e.response.data.message === 'Validation failed') {
                    const validationErrors = e.response.data.validationError;
                    if (validationErrors.newPassword) {
                        openNotification('error', 'Başarısız', `Yeni şifreniz büyük harf, küçük harf, ve rakam içermelidir ve en az 8 karakter olmalıdır!`);
                    } else {
                        openNotification('error', 'Başarısız', 'Tek Kullanımlık Parolanız hatalı veya şifreniz güçsüz! Kontrol ederek tekrar deneyin.');
                    }
                } else {
                    openNotification('error', 'Başarısız', 'Girilen Tek Kullanımlık Parola Hatalı! Kontrol ederek tekrar deneyin.');
                }
                setLoading(false);
            });
    };

    return (
        <>
            <Row>
                <Col>
                    {isPasswordChanged ? (
                        <Row>
                            <Title level={4}>Şifreniz başarıyla güncellenmiştir.</Title>
                            <p>
                                Yeni şifrenizle güvenli bir şekilde hesabınıza giriş yapabilirsiniz.
                            </p>
                        </Row>
                    ) : (
                        <>
                            <Form onFinish={onFinishOtpAndNewPassword}>
                                <Row align={'middle'} justify={'center'}>
                                    <Title level={2} className='form-header-title'>Tek Kullanımlık Parola ve Yeni Şifre</Title>
                                    <Paragraph className='fs-20 fw-400 font-color-subtext'>
                                        Lütfen E-Postanınza gelen Tek Kullanımlık Parolayı ve yeni şifrenizi giriniz.
                                    </Paragraph>
                                </Row>
                                <Form.Item
                                    name='otp'
                                    label="Tek Kullanımlık Parola"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Lütfen Tek Kullanımlık Parolayı giriniz!' }]}
                                >
                                    <Input.OTP
                                        size='large'
                                        length={6}
                                        value={otp}
                                        onChange={setOtp}
                                        formatter={(str) => str.toUpperCase()}
                                        className='otp-input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='newPassword'
                                    label="Şifre"

                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Lütfen yeni şifrenizi giriniz!' }]}
                                >
                                    <Input.Password
                                        size="large"
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        className='d-input'
                                        placeholder='Yeni Şifre'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='confirmPassword'
                                    dependencies={['newPassword']}
                                    label="Şifre Tekrar*"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: true, message: 'Lütfen yeni şifrenizi tekrar giriniz!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        className='d-input'
                                        size="large"
                                        placeholder='Yeni Şifre (Tekrar)'
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" loading={loading} size="large" className="w-full" type="primary">
                                        Değiştir
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    )}
                </Col>
            </Row>
            <Row className=' mt-24' justify={'center'}>
                <Col>
                    <p className='fs-18 font-color-subtext fw-400'>
                        Şifreni biliyor musun? <Link className='font-color-dark-purple fw-400 fs-18' to={'/giris'}>Giriş Yap</Link>
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default ForgotToNewPassword;