import {http} from "../http-common";
import { IUserRequest, IUserResponse } from "../types/User";


const getMe = () => {
    return http.get<IUserResponse>(`/students/me`);
};

const getParent = () => {
    return http.get<IUserResponse>(`/parents/me`);
};

const getParentById = (id:any) => {
    return http.get<IUserResponse>(`/parents/${id}`);
};

const createParent = (data: any) => {
    return http.post<any>(`/parents`, data);
};

const updateMe = (data: any) => {
    return http.patch<IUserRequest>(`/students/me`, data);
};

const updateParent = (id: any, data: any) => {
    return http.patch<any>(`/parents/${id}`, data);
};

const removeParent = (id: any) => {
    return http.delete<any>(`/parents/${id}`);
};

const UserService = {
    getMe,
    updateMe,
    getParent,
    createParent,
    removeParent,
    updateParent,
    getParentById
};

export default UserService;