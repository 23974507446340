import { Calendar, Card, Badge, Tooltip, Tag, Button, Popconfirm, Row, Col, Select, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReminderService from '../modules/Reminder/Services/ReminderService';
import { IReminderListResponse } from '../modules/Reminder/Types/Reminder';
import CreateReminder from '../modules/Reminder/Components/CreateReminder';
import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import { formatDate, formatTime, openNotification } from '../utils';
import LiveLessonCreate from '../components/LiveLesson/LiveLessonCreate';
import LiveLessonService from '../services/LiveLessonService';
import LiveLessonLink from '../components/LiveLesson/LiveLessonLink';
import { NavLink } from 'react-router-dom';
import UserService from '../services/UserService';
import LiveLessonCreditHistory from '../components/LiveLesson/LiveLessonCreditHistory';

const { Option } = Select;

const ReminderPage = () => {
    const [reminderList, setReminderList] = useState<IReminderListResponse[]>([]);
    const [statusFilter, setStatusFilter] = useState<any[]>([]);

    const [filter, setFilter] = useState<any>({
        startDate: dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
        status: statusFilter,
    });

    const onPanelChange = (value: Dayjs) => {
        const startOfMonth = value.startOf('month').format('YYYY-MM-DDT00:00:00');
        const endOfMonth = value.endOf('month').format('YYYY-MM-DDT23:59:59');

        setFilter((prev: any) => ({
            ...prev,
            startDate: startOfMonth,
            endDate: endOfMonth,
        }));
    };

    const getReminderList = () => {
        const statusQuery = statusFilter && statusFilter.length > 0 
            ? `&status=${statusFilter.join(',')}` 
            : '';
        
        // Include other filters like entityNames if needed in a similar way
        ReminderService.getAll(`?startDate=${filter?.startDate}&endDate=${filter?.endDate}${statusQuery}`)
            .then((response: any) => {
                let data: IReminderListResponse[] = response.data;
                setReminderList(data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    

    useEffect(() => {
        getReminderList();
    }, [filter, statusFilter]);

    const handleDelete = (reminderId: number) => {
        LiveLessonService.remove(reminderId)
            .then(() => {
                getReminderList();
                openNotification('success', 'Başarılı', 'Silindi.');
            })
            .catch((e: Error) => {
                openNotification('error', 'Hata', 'Silinme sırasında bir hata oluştu.');
                console.error('Silme sırasında hata:', e);
            });
    };

    const handleStatusChange = (value: string[]) => {
        setStatusFilter(value.length > 0 ? value : []);  
        setFilter((prev: any) => ({
            ...prev,
            status: value.length > 0 ? value : [],
        }));
    };
    

    const dateCellRender = (value: Dayjs) => {
        const dateString = value.format('YYYY-MM-DD');
        const remindersForDay = reminderList.filter(reminder =>
            dayjs(reminder.reminderDate).format('YYYY-MM-DD') === dateString
        );

        return (
            <ul className="events" style={{ listStyleType: 'none', paddingLeft: 5 }}>
                {remindersForDay.map((reminder: any, index) => {
                    const properties = JSON.parse(reminder?.properties);
                    return (
                        <li key={index}>
                            <Tooltip title={reminder.content} placement="top">
                                {reminder.status === 'PENDING' && <Popconfirm
                                    title="Silmek istediğinizden emin misiniz?"
                                    onConfirm={() => handleDelete(reminder?.id)}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <DeleteOutlined />
                                </Popconfirm>}
                                {reminder.status === 'ACCEPT' && <LiveLessonLink link={properties.link}></LiveLessonLink>}
                                <b style={{ marginLeft: 8 }} >{formatTime(reminder.reminderDate)}</b> - {properties.lessonName}
                            </Tooltip>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <Card title="Takvim" bodyStyle={{ paddingTop: 0 }}
            extra={
                <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Col>
                        <Row justify={"space-between"} align={"middle"}>
                          <LiveLessonCreditHistory/>
                        </Row>
                    </Col>
                    <Col>
                        <LiveLessonCreate getLiveLessons={() => { getReminderList(); }} />
                    </Col>
                    <Col>
                    <Select
    mode="multiple"
    placeholder="Durum seçin"
    onChange={handleStatusChange}
    allowClear
    size="large"
    style={{ width: 200 }}
>
    <Option value="PENDING">Beklemede</Option>
    <Option value="ACCEPT">Onaylandı</Option>
    <Option value="REJECT">Reddedildi</Option>
    <Option value="COMPLETED">Tamamlandı</Option>
</Select>

                    </Col>
                    <Col>
                        <NavLink to="/">
                            <Button size="large">Geri</Button>
                        </NavLink>
                    </Col>
                </Row>
            }
        >
            <Calendar
                mode="month"
                onPanelChange={onPanelChange}
                dateCellRender={dateCellRender}
            />
        </Card >
    );
};

export default ReminderPage;