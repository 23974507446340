import { Badge, Button, Card, Col, Progress, ProgressProps, Row, Space, Tooltip, Typography } from 'antd'
import { ReactComponent as CheckIcon } from '../../assets/Packages/check-icon.svg';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Meta from 'antd/es/card/Meta';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import CourseCover from '../../assets/course-cover.svg'

interface MyPackagesItemCardProps {
    packageItem: any
}

const CourseCard: React.FC<MyPackagesItemCardProps> = ({ packageItem }) => {

    const { Title, Text } = Typography;

    const [watchedDuration, setWatchedDuration] = useState(0);
    const [watchedDurationRatio, setWatchedDurationRatio] = useState<any>(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [totalVideo, setTotalVideo] = useState(0);
    const [totalDocument, setTotalDocument] = useState(0);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [totalSubject, setTotalSubject] = useState(0);

    useEffect(() => {
        if (packageItem?.subjects?.length > 0) {
            const totalDuration = packageItem?.totalDuration;
            const watchedDuration = packageItem?.watchedDuration;
            const totalVideo = packageItem?.totalVideo;
            const totalDocument = packageItem?.totalDocument;
            const totalQuestion = packageItem?.totalQuestion;
            setWatchedDuration(watchedDuration);
            setTotalDuration(totalDuration);
            setTotalVideo(totalVideo);
            setTotalDocument(totalDocument);
            setTotalQuestion(totalQuestion);
            setTotalSubject(packageItem?.subjects.length);
            if (totalDuration > 0 && watchedDuration >= 0) {
                const percentageWatched = (watchedDuration / totalDuration) * 100;
                setWatchedDurationRatio(percentageWatched.toFixed(0))
            }
        }
    }, [])

    return (
        <>
            <NavLink to={`/course/${packageItem?.id}/detail`}>
                <Card
                                                bodyStyle={{ padding: 16, minHeight: 90 }}
                    className='bg-blur border-none'
                    cover={
                        <img
                            alt="example"
                            src={CourseCover}
                        />
                    }
                >
                    <Title level={5}>{packageItem?.name}</Title>
                    <Text>{watchedDurationRatio}%</Text>
                    <Progress strokeColor={'#935ac0'}
                        size="default"
                        showInfo={false}
                        percent={watchedDurationRatio} status="active" />
                </Card>
            </NavLink>
        </>
    )
}

export default CourseCard